import React,{useEffect, useState} from 'react'; 
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Layout/AdminSidebar';
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";
import { Link } from 'react-router-dom';

 const Dashboard1 = () => 
 {

  const [data, setData] = useState({})

  const getDashboard = async() => {    
    const { data } = await axios.get(`${get_url.local_url}dashboard-data`);
    console.log(data);


    setData(data.data)
  }

  let header = { headers: {
    "Authorization" : `Bearer ${localStorage.getItem('tokens')}`,
    // "event_id" : localStorage.getItem('event_id'),
    "user_role" : localStorage.getItem('role')
  }}

  const getUserDetail = async() => { 
    await axios.get(`${get_url.local_url}get-user`,header).then((response) => {
        const get_user_detail  = response.data.data[0]
        console.log(get_user_detail);
        // if ('undefined' === typeof get_user_detail || null == get_user_detail) {
        // navigate('/Login')
        // }
        // setUserDetail(get_user_detail) 
    })
}


  useEffect(() => {
    getDashboard();
    getUserDetail();
  }, [ ]);

  return ( 
    <>
    <AdminHeader />
    <div class="container-fluid">
      <div class="row">
       <AdminSideBar />
        <main class="col-md-9 ms-sm-auto col-lg-10 "> 
         <div className='events_list Page_box'>
            <div> 
              <h3 className='Welcome_text'>Welcome Super Admin!</h3>
            </div>
            <div className="event_list_head mb-3 p-2">
              {/* <div className='eve_list_heading'>
                <h5>Upcoming Events</h5>
              </div> */}
              <hr class="dropdown-divider" />
              <div className='see_all'>
                <div> {/*
                  <Link to="#" variant="primary">See All</Link> */}
                </div>
              </div>
            </div>
            <div className='events_row'>
              <Link to="/Events" style={{textDecoration: 'none'}}>
                <div className='event_card card '>
                  <h5>Total Events</h5>
                  <p className='Event_owner'>Total Events</p>
                  <p className='Eve_Discri'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><div className='Eve_status Eve_open'> {data.events}</div>
                </div>
              </Link>
              <Link to="/Users" style={{textDecoration: 'none'}}>
                <div className='event_card card '>
                  <h5> Total Users</h5>
                  <p className='Event_owner'>Total Users</p>
                  <p className='Eve_Discri'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>  <div className='Eve_status Eve_open'>  {data.users}</div>
                </div>
              </Link>
              <Link to="/Blogs" style={{textDecoration: 'none'}}>
                <div className='event_card card shadow'>
                  <h5> Blogs</h5>
                  <p className='Event_owner'>Blogs</p>
                  <p className='Eve_Discri'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>  <div className='Eve_status Eve_open'> {data.blogs}</div>
                </div> 
              </Link>
            </div>
          </div>
          <div className='events_list Page_box'>
            <div className="event_list_head mb-3 p-2">
              {/* <div className='eve_list_heading'>
                <h5> Closed Events</h5>
              </div> */}
              <div className='see_all'>
                <div>
                </div>
              </div>
            </div>
            <div className='events_row'>
              <Link to="/Subscription" style={{textDecoration: 'none'}}>
                <div className='event_card card '>
                  <h5>Subscription Plan</h5>
                  <p className='Event_owner'> Subscription Plan</p>
                  <p className='Eve_Discri'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>  <div className='Eve_status Eve_closed'> {data.subscription}</div>
                </div>
              </Link>
              {/* <div className='event_card card '>
                <h5>Holiday</h5>
                <p className='Event_owner'>Shubh</p>
                <p className='Eve_Discri'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> <div className='Eve_status Eve_closed'> Closed</div>
              </div>  */}
            </div>
          </div>
        </main>
      </div>
    </div>
  </> ); 
  } 
  export default Dashboard1;