import React,{useEffect, useState} from 'react'; 
// import '../../../public/css/super.css';
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Layout/AdminSidebar';
import DataTable from 'react-data-table-component';
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";
import { toast } from "react-toastify";
import moment from 'moment';



const Index = () => {

  const [data, setData] = useState([])
  const [page, setPage] = useState(1)

  const handleDeleteEvent = async (row) => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'multipart/form-data',
    }
    const { _id } = row;
    
    let formData = new FormData()
    formData.append('id', _id)    
    var url = `event-delete/`;
    
    await axios.post(`${get_url.local_url}event-delete`, row, headers )  
    .then(response => {
        const { data } = response
        console.log(data)
        alert(data.message);
        getAllEvents()
    }).catch(error => {
        throw (error);
    });
  }

  
    const columns = [
        {
          name: '#Sno.',
          cell: (row, index) => ((page - 1) * 10 ) + index + 1  //RDT provides index by default
        },
        {
          name: 'Event Name',
          selector: row => row.name, 
          sortable: true,
        },
        // {
        //   name: 'Email',
        //   selector: row => row.email,   
        //   sortable: true,
        // },
        {
          name: 'Event Start',
          selector: row => moment(row.start_date).format('DD-MM-YYYY'),                    
          sortable: true,
        },
        {
          name: 'Event End',
          selector: row => moment(row.end_date).format('DD-MM-YYYY'),
        },
        {
          name: 'City',
          selector: row => row.city,
        },
        {
          name: 'State',
          selector: row => row.state,
        },
        {
          name: 'Event Size',
          selector: row => row.event_size,
        },
        {
          name: 'Budget',
          selector: row => row.budget,
        },
        {
          name: 'Delete',
          selector: (row, index) => <button  className=" btn action_btn" onClick={() => handleDeleteEvent(row)} ><i class="fas fa-trash"></i></button>
        },
      ];

    const handlePageChange = page => {
      setPage(page)
    };
   
    const getAllEvents = async() => {      
        let datas = await axios.get(`${get_url.local_url}events`)
        console.log(datas.data.data.length);

        var data = (datas.data.data).filter(function (el, key) {
          console.log(el.name);
          return 'undefined' !== typeof el.name && '' != el.name;
        });

        console.log(data);

        setData(data)
    }

    useEffect(() => {
        getAllEvents();
    }, [ ]);

    return (
        <>
        <AdminHeader />
            <div className="container-fluid">
                <div className="row">
                    <AdminSideBar />
                    <main className="col-md-9 ms-sm-auto col-lg-10 ">                        
                        <div className='events_list Page_box_subscription'>
                            <DataTable
                                title="Events"
                                columns={columns}
                                data={data}
                                pagination
                                highlightOnHover
                                onChangePage={handlePageChange}
                            />  
                        </div>
                    </main>
                </div>
            </div>
                
            
        </>
    );
}

export default Index;
