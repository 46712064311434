import React from 'react'; 
import '../../SuperAdmin/css/style.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";
import { toast } from "react-toastify";

const EditModal = (props) => 
{  
    
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const onSubmit = async (data) => {
        const headers = {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'multipart/form-data',
        }        
       
        await axios.post(`${get_url.local_url}update_subscription`, data, headers )
        .then(response => {
           const { data } = response
           props.setEditSub(false)
           toast.success(data.message);
        }).catch(error => {
           throw (error);
        });

    }
   
    return( 
   <>
    <Modal show={props.editSub} onHide={props.handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Subscription Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register('_id', { required: true })} defaultValue={props.plan._id} />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Plan Name</Form.Label>
                    <Form.Control type="text" {...register('plan_name', { required: true })} defaultValue={props.plan.plan_name} placeholder="Enter Plan Name" />                   
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control type="text" {...register('plan_amount', { required: true })} defaultValue={props.plan.plan_amount} placeholder="Plan Amount" />
                </Form.Group> 
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Event Created</Form.Label>
                    <Form.Control type="text" {...register('create_events', { required: true })} defaultValue={props.plan.create_events} placeholder="Event Created" />
                </Form.Group>   
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Team Member</Form.Label>
                    <Form.Control type="text" {...register('team_member', { required: true })} defaultValue={props.plan.team_member} placeholder="Team Member" />
                </Form.Group>  
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Role Based Acces To Team</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('role_based_access', { required: true })} defaultValue={props.plan.role_based_access}>
                        <option>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>     
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Automated Checklist</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('automated_checklist', { required: true })} defaultValue={props.plan.automated_checklist}>
                        <option>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Budget Tool</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('budget', { required: true })} defaultValue={props.plan.budget}>
                        <option value=''>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>  
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Checklist Reminder</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('checklist_reminder', { required: true })} defaultValue={props.plan.checklist_reminder}>
                        <option value=''>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>  
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Crm Access</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('is_crm', { required: true })} defaultValue={props.plan.is_crm}>
                        <option value=''>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>  
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Email Support</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('email_support', { required: true })} defaultValue={props.plan.email_support}>
                        <option value=''>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Chat Support</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('chat_support', { required: true })} defaultValue={props.plan.chat_support}>
                        <option value=''>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Free Training</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('training', { required: true })} defaultValue={props.plan.training}>
                        <option value=''>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Additional Feature</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('training', { required: true })} defaultValue={props.plan.training}>
                        <option value=''>Select Option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </Form.Select>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Update
                </Button>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleEditClose}>
            Close
          </Button>         
        </Modal.Footer>
      </Modal>
    </> 
    ); 
  } 
export default EditModal;