import React,{useEffect, useState} from 'react'; 
// import '../../../public/css/super.css';
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Layout/AdminSidebar';
import DataTable from 'react-data-table-component';
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";
import { toast } from "react-toastify";
import Button from 'react-bootstrap/Button';
import AddTaskModal from './addNewTask';



const Index = () => {

  const [data, setData] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [page, setPage] = useState(1)

  const handleDeleteEvent = async (row) => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'multipart/form-data',
    }

    await axios.post(`${get_url.local_url}define_task_delete`, row, headers )  
    .then(response => {
        const { data } = response
        console.log(data)
        toast.success(data.message);
        getAllTask()
    }).catch(error => {
        throw (error);
    });
  }

    const columns = [
        {
          name: '#Sno.',
          cell: (row, index) => ((page - 1) * 10 ) + index + 1  //RDT provides index by default
        },   
        {
          name: 'Category Name',
          selector: row => row.sub_category.category_detail[0].name,   
          sortable: true,
        },     
        {
          name: 'Sub Category Name',
          selector: row => row.sub_category.name,   
          sortable: true,
        },
        {
          name: 'name',
          selector: row => row.name ,                      
          sortable: true,
        },        
        {
          name: 'Delete',
          selector: (row, index) => <button  className=" btn action_btn" onClick={() => handleDeleteEvent(row)} ><i class="fas fa-trash"></i></button>
        },
      ];

      const handlePageChange = page => {
        setPage(page)
      };
   
    const getAllTask = async() => {      
        const { data } = await axios.get(`${get_url.local_url}get_task_define`)
        console.log(data, 'herere')
        setData(data.data)
    }

    useEffect(() => {
      getAllTask();
    }, [ ]);

    return (
        <>
        <AdminHeader />
            <div className="container-fluid">
                <div className="row">
                    <AdminSideBar />
                    <div className='events_list'>
                        <div className="event_list_head su_btn_box">                        
                            <div className='Subscription_btn'> 
                                <Button variant="primary" onClick={handleShow} >Add Task</Button>
                            </div>
                        </div>            
                    </div>
                    <main className="col-md-9 ms-sm-auto col-lg-10 ">                        
                        <div className='events_list Page_box_subscription'>
                            <DataTable
                                title="Task"
                                columns={columns}
                                data={data}
                                pagination
                                highlightOnHover
                                onChangePage={handlePageChange}
                            />  
                        </div>
                    </main>
                </div>
            </div>
            <AddTaskModal show={show} setShow={setShow} getAllTask={getAllTask} handleClose={handleClose}/>   
            
        </>
    );
}

export default Index;
