import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'; 
import Dropdown from 'react-bootstrap/Dropdown';
import '../css/style.css';
import DropdownButton from 'react-bootstrap/DropdownButton'; 

const AdminHeader = () => {
    const [userData, setUserData] = useState([])
    const navigate = useNavigate();
    const handleLogout=()=>{
        window.localStorage.clear();
        // props.resetEvents();
        // toast.success("User Logout Successfully")
        navigate('/Signin');
      }

   // const userData =  JSON.parse(localStorage.getItem('superAdminData'));
    return (
        <>        
        <header class="navbar sticky-top bg-light flex-md-nowrap p-2 Ad_head shadow">
            <Link class="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/Dashboard1">
            <img src='Assets/img/logo.png' alt='' />
            </Link>
            <button class="navbar-toggler dak position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>  
            <div className="header_right_panel">
                <Dropdown>
                <Dropdown.Toggle className='profile_toggle Ad_avatar' id="dropdown-basic" align="end">
                    <img alt="User" src="Assets/img/Icon_Profile.png" />
                </Dropdown.Toggle>
                <Dropdown.Menu className='Profile_Options' align="end">
                
                    <h3 className='Welcome_text'>Welcome <br />{userData.first_name} {userData.last_name}</h3>
                    <Dropdown.Item className='Profile_Option' href="#/action-1">
                    <Link to='/AdminUpdateProfile' style={{"textDecoration":"none","color":"black"}}><i class="Profile_icons fas fa-user-circle"></i> Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Item className='Profile_Option' href="#">
                    <Link to='/AdminResetPassword' style={{"textDecoration":"none","color":"black"}}><i class="Profile_icons fas fa-lock"></i> Change Password</Link>
                    </Dropdown.Item>
                    <Dropdown.Item className='Profile_Option' onClick={handleLogout}>
                    <i class="Profile_icons fas fa-sign-out-alt"></i> Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
            </div>            
        </header>
        </>
    );
}

export default AdminHeader;
