import React, { useState, useRef, useEffect, Component } from 'react';
// import '../../../public/css/super.css';
import Button from 'react-bootstrap/Button';
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Layout/AdminSidebar';
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";


const Index = () => {
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)

    const handleUserDelete = async(row) => {
      const headers = {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'multipart/form-data',
      }       

    await axios.post(`${get_url.local_url}delete-user`, row, headers ) 
      .then(response => {
          const { data } = response
         
          alert(data.message);
          getUsers()
      }).catch(error => {
          throw (error);
      });
    }

    const columns = [
        {
          name: '#Sno.',
          cell: (row, index) => ((page - 1) * 10 ) + index + 1  //RDT provides index by default
        },
        {
          name: 'Name',
          selector: row => row.first_name +' '+ row.last_name, 
          sortable: true,
        },
        {
          name: 'Email',
          selector: row => row.email,   
          sortable: true,
        },
        {
          name: 'Phone Number',
          selector: row => row.phone_number,                      
          sortable: true,
        },
        {
          name: 'Role',
          selector: row => row.role,
        },
        {
          name: 'Delete',
          selector: (row, index) => <button  className=" btn action_btn" onClick={() => handleUserDelete(row)} ><i class="fas fa-trash"></i></button>
        },
    ];

    
    const handlePageChange = page => {
      setPage(page)
    };

    const getUsers = async () => {
      const { data } = await axios.get(`${get_url.local_url}get-users`)
      setData(data.data)
    }
    useEffect(() => {
        getUsers();
    }, [ ]);
    

    return (
        <>
        <AdminHeader></AdminHeader>
            <div className="container-fluid">
                <div className="row">
                    <AdminSideBar />
                    <main className="col-md-9 ms-sm-auto col-lg-10 "> 
                        <div className='events_list Page_box_subscription'>
                            <DataTable
                                title="Users"
                                columns={columns}
                                data={data}
                                pagination
                                highlightOnHover
                                onChangePage={handlePageChange}
                            />                            
                        </div>
                    </main>
                </div>
            </div>                
        </>
    );
}

export default Index;