import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Link, useNavigate} from 'react-router-dom';
import  Header  from './common/Header'; 
import  get_url  from './common/ApiCaller';
import axios from "axios";
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import {useHistory} from 'react-router-dom';

const countryStateCity= require('countrycitystatejson')

const Step5 = (props) => { 
    const navigate = useNavigate(); 
    const [eventData, setEventData] = useState({}); 
    const [states, setStates] = useState({}); 
    const [cities, setCities] = useState({}); 

    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "event_id" : localStorage.getItem('event_id'),
        "user_role" : localStorage.getItem('role')
    }}

    const submitForm = ()=>{
        let countryVal = $('#country').attr('data-id');
        let country = $('#country').text();
        let state = $('#state').attr('data-id') 
        let city = $('#city').attr('data-id')

        // let countryVal = $('#country :selected').val();
        // let country = $('#country :selected').text();
        // let state = $('#state').val() 
        // let city = $('#city').val()
        console.log(countryVal);
        if ('' === countryVal || 'undefined' == typeof countryVal) {
            alert('Please select the country.'); return false;
        }

        if ('' === state || 'undefined' == typeof state) {
            alert('Please select the state.'); return false;
        }

        let event_data = {
            country,
            state,
            city,
            event_id : localStorage.getItem("event_id")
        }

        axios.post(`${get_url.local_url}event-all-form`, event_data, header).then((response) => { 
            navigate('/step6');
        })
    }
    const fetchEventData = async() => { 
        let event_id = localStorage.getItem("event_id")
        if (null == event_id) {
            navigate('/PricingPlans')
        }
        await axios.get(`${get_url.local_url}event-data/${event_id}`).then((response) => {
            const get_event_data  = response.data[0] 
            if ('undefined' === typeof get_event_data) {
                navigate('/Dashboard')
            }
            setEventData(get_event_data)  
            if ('undefined' !== typeof get_event_data.city) {
                $('#city').val(get_event_data.city).text(get_event_data.city).attr('data-id', get_event_data.city).addClass('text-dark')
            }
            if ('undefined' !== typeof get_event_data.state) {
                $('#state').val(get_event_data.state).text(get_event_data.state).attr('data-id', get_event_data.state).addClass('text-dark')
            }
            if ('undefined' !== typeof get_event_data.country) {
                $('#country').val(get_event_data.country).text(get_event_data.country).attr('data-id', get_event_data.country).addClass('text-dark');
            }
        })
    };
    useEffect(() => {
        fetchEventData();
    },[]) 

let countries = countryStateCity.getAll();

const getStates = (event) => {
    // let countryName = event.target.value
    let countryName = event.target.id
    console.log(event.target.text);
    $('#country').text(event.target.text).attr('data-id', countryName).addClass('text-dark');
    let states = countryStateCity.getStatesByShort(countryName);
    // $('#state').prop('selectedIndex', 0);
    $('#state').text('Select State');
    setStates(states);
    setCities({});
}
 
const getCitites = (event) => {
    // let countryName = $('#country').val();
    let countryName = $('#country').attr('data-id');
    // let cityName = event.target.value
    let stateName = event.target.id
    $('#state').text(stateName).attr('data-id', stateName).addClass('text-dark');
    let citites = countryStateCity.getCities(countryName, stateName);
    setCities(citites);
}

const selectCity = (event) => {
    let cityName = event.target.id
    $('#city').text(cityName).attr('data-id', cityName).addClass('text-dark');
}
return (
            <>
            <Header/>
                <div className="footstep-outer">
                    <div className="foot-step shadow-lg"> Step 5 of 7</div>
                </div>
                <section className="planing_outer"> 
                    <div className="container">
                    <Link to="/step4">
                {" "}
                <i
                  style={{ fontSize: "50px" }}
                  class="fa-sharp fa-solid fa-arrow-left"
                ></i>
              </Link>
                        <div className="inner_heading">
                            <h4>Where is your Event ?</h4>
                        </div>  
                        <div className="row">  
                            <div className="col-lg-10 m-auto">
                                <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="input_outer with_text">
                                        <label>Country</label>
                                        {/* <select className="form-control" id="country" onChange={(e) => getStates(e)}>
                                        <option value="" >Select country</option>
                                            {Object.keys(countries).map(key => 
                                                <option value={key}>{countries[key].name}</option>
                                            )}
                                        </select> */}
                                        
                                        <DropdownButton id="country" title="Select Country" className="button_text_more" >
                                            {Object.keys(countries).map(key => 
                                                <Dropdown.Item onClick={(e) => getStates(e)} id={key} >{countries[key].name}</Dropdown.Item>
                                            )}
                                        </DropdownButton>
                                        
                                        {/* <input type="text" placeholder="Country" id="Country" name="Country" autoComplete='off'/>  */}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="input_outer with_text no_t5 m_top">
                                        <label>State</label>
                                        {/* <select className="form-control" id="state" onChange={(e) => getCitites(e)}>
                                        <option value="" >Select state</option>
                                            {Object.keys(states).map(key => 
                                                <option value={states[key]}>{states[key]}</option>
                                            )}
                                        </select> */}

                                        <DropdownButton id="state" title="Select State" className="button_text_more">
                                            {Object.keys(states).map(key => 
                                                <Dropdown.Item className="Dropdown_country" onClick={(e) => getCitites(e)} id={states[key]} >{states[key]}</Dropdown.Item>
                                            )}
                                        </DropdownButton>

                                        {/* <input type="text" id="state" placeholder="State" name="state" autoComplete='off'/>  */}
                                    </div>     
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="input_outer with_text location_field">
                                        <label>City</label>
                                        {/* <select className="form-control" id="city">
                                        <option value="" >Select city</option>
                                            {Object.keys(cities).map(key => 
                                                <option value={cities[key]}>{cities[key]}</option>
                                            )}
                                        </select> */}

                                        <DropdownButton id="city" title="Select City" className="button_text_more">
                                            {Object.keys(cities).map(key => 
                                                <Dropdown.Item onClick={(e) => selectCity(e)} id={cities[key]} >{cities[key]}</Dropdown.Item>
                                            )}
                                        </DropdownButton>

                                        {/* <input type="text" placeholder="City" id="city" name="city" autoComplete='off'/>  */}
                                    </div>
                                </div>
                                
                                </div>      
                            </div>
                            <div className="btn_outer no_edit later3">
                                <p className="p_text">You can always edit it later</p>	
                                <button type='submit' className='btn btn-primary' onClick={(e)=>{submitForm()}}> Continue </button>
                            </div>
                        </div>
                    </div> 
                </section>
            </>
        )
} 
export default Step5;