import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import get_url from "./common/ApiCaller";
import EventDetails from "./EventDetails";
import axios from "axios";
import SideBar from "./SideBar";
import $ from "jquery";
import { ThreeDots } from "react-loader-spinner";

const CheckList = (props) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [startDate, setStartDate] = useState("");
  const navigate = useNavigate();

  const [openCalander, setopenCalander] = React.useState(false);
  const [userListing, setUserListing] = React.useState(false);
  /** Modal Hooks Start */
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [append, setAppend] = useState("");
  const [checkname, setCheckname] = useState("");
  const [noOfRows, setNoOfRows] = useState(1);
  const [multiselectValue, setMultiselectValue] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [taskList, setTaskList] = useState([]);
  const exampleInput = useRef();
  const rowsData = [];
  const [categoryList, setCategoryList] = useState([]);
  const [eventD, setEventD] = useState(false);
  const [checkedData, setCheckedData] = useState(false);
  const [checkedArrData, setCheckedArrData] = useState([]);
  const [optionSelect, setOptionSelect] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [state, setState] = useState("");
  const [assignUser, setAssignUser] = useState("");
  const [checkedBox, setCheckedBox] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [userData, setUserData] = useState([]);
  const [restrictedTask, setRestrictedTask] = useState([]);
  const [notDefineTask, setNotDefineTask] = useState([]);
  const [deletedTask, setDeletedTask] = useState([]);
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const eventTypeOf = localStorage.getItem("event_type");

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const delete_task = async (task_id, is_pre_define) => {
    setIsLoaderDisplay(true);
    if (window.confirm("Are you sure to delete this task.")) {
      is_pre_define =
        "undefined" === typeof is_pre_define ? true : is_pre_define;
      await axios
        .delete(
          `${get_url.local_url}delete-task/${task_id}/${is_pre_define}`,
          header
        )
        .then((response) => {
          fetch_static_category_data();
          //$(`#task_id_${task_id}`).css({"background-color":"#c62929"}).hide(1000)
          alert("Task deleted successfully.");
          // navigate('/CheckList/'+localStorage.getItem('event_id'));
          window.location.reload();
        })
        .catch((error) => console.log(error));
    } else {
      setIsLoaderDisplay(false);
    }
  };

  window.delete_add_more = function (randon_task_id) {
    $(`.remove_task_id_${randon_task_id}`).hide(1000);
  };
  function random_password() {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 1; i <= 8; i++) {
      var char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }
    return pass;
  }
  const add_new_task = (
    category_id,
    sub_category_id,
    task_id,
    get_user_data
  ) => {
    var multy_option;
    get_user_data.length > 0 &&
      get_user_data.map((single_user_data, user_key) => {
        multy_option += `<option value=${single_user_data._id}>
               ${single_user_data.first_name}
            </option>`;
      });
    let random_number = random_password();
    let tr_display = `<tr class='deltask new_added_task remove_task_id_${random_number}'>
                           <td class="custom_ty6" onClick="delete_add_more('${random_number}')">
                              <img class='custom_ty66' src="../Assets/img/delete.png" />
                           </td>
                           <td class="custom_labe8">
                              <input type="text" id={'name'} placeholder="Task name" class="task_name" name="task_name[@${category_id}|${sub_category_id}|${task_id}|insert|false@]" /><img src="../Assets/img/edit.png" />
                           </td>
                           <td class="custom_DATE_PICKER">
                              <div class="cutom_plus">
                                 <img src="../Assets/img/plus.png" />                                       
                                 <select class='task-option user_id' id={'user'}>
                                    <option value="">Choose a user</option>
                                       ${multy_option}
                                    }
                                 </select>                                      
                              </div> 
                           </td>
                           <td class='custom_task_name'>
                              <div class="cutom_plus">
                                 <img src="../Assets/img/plus.png" ></img>
                                 <input type="date" min=${todayDate} id='date' class="due_date" style="border:none" /> 
                              </div>
                           </td>     
                        </tr>`;
    $(`#add_task_${sub_category_id}`).prepend(tr_display);
  };
  const add_checklist = async () => {
    // setIsLoaderDisplay(true)
    var a_checklist_data = [];
    var a_user_id_data = [];
    var a_due_date_data = [];

    $(".user_id").each(function (k, v) {
      a_user_id_data[k] = $(this).val();
    });
    $(".due_date").each(function (k, v) {
      a_due_date_data[k] = $(this).val();
    });
    $(".task_name").each(function (k, v) {
      let get_name_data = v.name.split("@")[1];
      let category_id = get_name_data.split("|")[0];
      let sub_category_id = get_name_data.split("|")[1];
      let task_id = get_name_data.split("|")[2];
      let operation = get_name_data.split("|")[3];
      let is_pre_defined = get_name_data.split("|")[4];

      a_checklist_data[k] =
        category_id +
        "|" +
        sub_category_id +
        "|" +
        task_id +
        "|" +
        operation +
        "|" +
        is_pre_defined +
        "|" +
        $(this).val();
    });
    let checklist_data = {
      get_checklist_data: a_checklist_data,
      get_user_id_data: a_user_id_data,
      get_due_date_data: a_due_date_data,
    };

    await axios
      .post(`${get_url.local_url}add-check-list-data`, checklist_data, header)
      .then((response) => {
        $(".new_added_task").hide();
        //fetch_static_category_data()
        alert("Task added successfully.");
        //setIsLoaderDisplay(false)
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  const changeTaskName = (task_val, task_id) => {
    $(`#task_input_${task_id}`).val(task_val);
  };
  const fetch_static_category_data = async () => {
    setIsLoaderDisplay(true);
    let selected_category_id = localStorage.getItem("selected_category");
    let send_data = {
      selected_category_id: selected_category_id,
    };
    await axios
      .post(`${get_url.local_url}check-list-data`, send_data, header)
      .then((response) => {
        const all_category_data = response.data.data;
        setCategoryData(all_category_data);
        setIsLoaderDisplay(false);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${get_url.local_url}get-perticular-user`, header)
      .then((response) => {
        const all_user_data = response.data.data;
        setUserData(all_user_data);
      })
      .catch((error) => console.log(error));
  };
  const ristricted_task = async () => {
    await axios
      .get(`${get_url.local_url}ristricted-predefined-task-id`, header)
      .then((response) => {
        const restricted_task = response.data;
        setRestrictedTask(restricted_task);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${get_url.local_url}deleted-predefined-task-id`, header)
      .then((response) => {
        const deleted_task = response.data;
        setDeletedTask(deleted_task);
      })
      .catch((error) => console.log(error));
  };
  const getEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    await axios
      .get(`${get_url.local_url}event-data/${event_id}`)
      .then((response) => {
        const event_data = response.data[0];
        let selected_checklist = JSON.stringify(event_data.checklist_id);
        if (localStorage.getItem("selected_category") == null) {
          localStorage.setItem("selected_category", selected_checklist);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getEventData();
    ristricted_task();
    fetch_static_category_data();
  }, []);

  const [todayDate, setTodayDate] = useState(false);

  $(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;
    setTodayDate(maxDate);
  });

  return (
    <div>
      {isLoaderDisplay ? (
        <div className="overlay">
          <div className="center_dots">
            <ThreeDots height="80" width="80" radius="9" color="#0d6efd" />
          </div>
        </div>
      ) : (
        ""
      )}

      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>
      <section className="check_list">
        <SideBar />
        <div className="check_right">
          <div className="row justify-content-between">
            <div className="col-lg-10 col-md-6">
              <div className="assign_outer">
                <h2>Checklist</h2>
                <p>
                  Use this page to create, assign & keep track of checklist
                  items
                </p>
              </div>
            </div>
            <div className="col-lg-auto col-md-6">
              {eventTypeOf == "close_event" ? null : (
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    add_checklist();
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
          <hr />
          {categoryData.length > 0 &&
            categoryData.map((category_data, category_key) => {
              return (
                <>
                  <div className="category_label">
                    <h2 className="category_label_text">
                      {category_data.name}
                    </h2>
                  </div>
                  {category_data.sub_category_data.length > 0 &&
                    category_data.sub_category_data.map(
                      (sub_category_data, sub_category_key) => {
                        return (
                          <div className="accordion category_accordian" id="sa">
                            <div className={`item delitem`}>
                              <div className="item-header">
                                <h2 className="mb-0">
                                  <div className="btn btn-link checklist_head">
                                    <div className="title_head_outer1">
                                      <span className="title_heading">
                                        {sub_category_data.name}
                                      </span>
                                    </div>
                                    <div className="right_options">
                                      <div className="right90 mt-0">
                                        {eventTypeOf == "close_event" ? null : (
                                          <Link
                                            to="#"
                                            onClick={(e) => {
                                              add_new_task(
                                                category_data._id,
                                                sub_category_data._id,
                                                "000000000000000000000000",
                                                userData
                                              );
                                            }}
                                          >
                                            Add new task
                                          </Link>
                                        )}
                                      </div>
                                      <div>
                                        <button
                                          className={
                                            0 == sub_category_key
                                              ? "btn btn-link checklist_head"
                                              : "btn btn-link checklist_head collapsed"
                                          }
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#show_hide_func_${sub_category_data._id}`}
                                          aria-expanded={
                                            0 == sub_category_key
                                              ? "true"
                                              : "false"
                                          }
                                          aria-controls={"ar"}
                                        >
                                          <i className="fa fa-angle-down down-btn"></i>
                                        </button>
                                      </div>
                                      {/* <div className='delete_list'>
                                       <img className='delete-btn' src="../Assets/img/close_icon.png" />
                                    </div> */}
                                    </div>
                                  </div>
                                </h2>
                              </div>
                              <div
                                id={`show_hide_func_${sub_category_data?._id}`}
                                className={`collapse ${
                                  sub_category_key == 0 ? "show" : ""
                                }`}
                                aria-labelledby={"head"}
                                data-parent={"#accor"}
                              >
                                <div className="t-p">
                                  <div className="table-responsive">
                                    <input
                                      type="hidden"
                                      name="checklist"
                                      ref={exampleInput}
                                    />
                                    <table className="table custom_table">
                                      <thead>
                                        <tr>
                                          <th>&nbsp;</th>
                                          {/* <th>Done</th> */}
                                          <th>Task</th>
                                          <th>Assign To</th>
                                          <th>Due Date</th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        id={`add_task_${sub_category_data?._id}`}
                                      >
                                        {sub_category_data.task_data?.length >
                                          0 &&
                                          sub_category_data.task_data.map(
                                            (task_data, task_key) => {
                                              // Not show the restricted task
                                              if (
                                                restrictedTask.includes(
                                                  task_data._id
                                                )
                                              ) {
                                                return true;
                                              }
                                              if (
                                                deletedTask.includes(
                                                  task_data._id
                                                )
                                              ) {
                                                return true;
                                              }
                                              return (
                                                <tr
                                                  className="deltask"
                                                  id={`task_id_${task_data._id}`}
                                                >
                                                  <td
                                                    className="custom_ty6"
                                                    onClick={() => {
                                                      delete_task(
                                                        task_data._id,
                                                        task_data.pre_define
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      className="custom_ty66"
                                                      src="../Assets/img/delete.png"
                                                    />
                                                  </td>
                                                  <td className="custom_labe8">
                                                    <input
                                                    disabled={
                                                      eventTypeOf ==
                                                      "close_event"
                                                        ? true
                                                        : false
                                                    }
                                                      type="text"
                                                      placeholder="Task name"
                                                      className="task_name utm_custom_value"
                                                      name={`task_name[@${
                                                        category_data._id
                                                      }|${
                                                        sub_category_data._id
                                                      }|${task_data._id}|${
                                                        task_data.pre_define
                                                          ? "insert"
                                                          : "update"
                                                      }|${
                                                        task_data.pre_define
                                                      }@]`}
                                                      defaultValue={
                                                        task_data.name
                                                      }
                                                      id={`task_input_${task_data._id}`}
                                                      onChange={(e) => {
                                                        changeTaskName(
                                                          e.target.value,
                                                          task_data._id
                                                        );
                                                      }}
                                                    />
                                                    <img src="../Assets/img/edit.png" />
                                                  </td>
                                                  <td className="custom_DATE_PICKER">
                                                    <div
                                                      className="cutom_plus"
                                                      onClick={() =>
                                                        setUserListing(true)
                                                      }
                                                    >
                                                      <img src="../Assets/img/plus.png" />
                                                      <select
                                                      disabled={
                                                         eventTypeOf ==
                                                         "close_event"
                                                           ? true
                                                           : false
                                                       }
                                                        className="task-option user_id"
                                                        id={"user"}
                                                      >
                                                        <option value="">
                                                          Choose a user
                                                        </option>
                                                        {userData.length > 0 &&
                                                          userData.map(
                                                            (
                                                              user_data,
                                                              user_key
                                                            ) => {
                                                              return (
                                                                <option
                                                                  value={
                                                                    user_data._id
                                                                  }
                                                                  selected={
                                                                    user_data._id ==
                                                                    task_data.user_assign_id
                                                                      ? "selected"
                                                                      : ""
                                                                  }
                                                                >
                                                                  {
                                                                    user_data.first_name
                                                                  }
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                      </select>
                                                    </div>
                                                  </td>
                                                  <td className="custom_task_name">
                                                    <div className="cutom_plus">
                                                      <img src="../Assets/img/plus.png"></img>
                                                      <input
                                                      disabled={
                                                         eventTypeOf ==
                                                         "close_event"
                                                           ? true
                                                           : false
                                                       }
                                                        type="date"
                                                        id="date"
                                                        defaultValue={
                                                          task_data.due_date?.split(
                                                            "T"
                                                          )[0]
                                                        }
                                                        className="due_date"
                                                        style={{
                                                          border: "none",
                                                        }}
                                                        min={todayDate}
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        {sub_category_data.not_define_task_data
                                          ?.length > 0 &&
                                          sub_category_data.not_define_task_data.map(
                                            (task_data, task_key) => {
                                              return (
                                                <tr
                                                  className="deltask"
                                                  id={`task_id_${task_data._id}`}
                                                >
                                                  <td
                                                    className="custom_ty6"
                                                    onClick={() => {
                                                      delete_task(
                                                        task_data._id,
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      className="custom_ty66"
                                                      src="../Assets/img/delete.png"
                                                    />
                                                  </td>
                                                  <td className="custom_labe8">
                                                    <input
                                                    disabled={
                                                      eventTypeOf ==
                                                      "close_event"
                                                        ? true
                                                        : false
                                                    }
                                                      type="text"
                                                      placeholder="Task name"
                                                      className="task_name utm_custom_value"
                                                      name={`task_name[@${category_data._id}|${sub_category_data._id}|${task_data._id}|'update'|false@]`}
                                                      defaultValue={
                                                        task_data.name
                                                      }
                                                      id={`task_input_${task_data._id}`}
                                                      onChange={(e) => {
                                                        changeTaskName(
                                                          e.target.value,
                                                          task_data._id
                                                        );
                                                      }}
                                                    />
                                                    <img src="../Assets/img/edit.png" />
                                                  </td>
                                                  <td className="custom_DATE_PICKER">
                                                    <div
                                                      className="cutom_plus"
                                                      onClick={() =>
                                                        setUserListing(true)
                                                      }
                                                    >
                                                      <img src="../Assets/img/plus.png" />
                                                      <select 
                                                      disabled={
                                                         eventTypeOf ==
                                                         "close_event"
                                                           ? true
                                                           : false
                                                       } className="task-option user_id">
                                                        <option value="">
                                                          Choose a user
                                                        </option>
                                                        {userData.length > 0 &&
                                                          userData.map(
                                                            (
                                                              user_data,
                                                              user_key
                                                            ) => {
                                                              return (
                                                                <option
                                                                  value={
                                                                    user_data._id
                                                                  }
                                                                  selected={
                                                                    user_data._id ==
                                                                    task_data.user_assign_id
                                                                      ? "selected"
                                                                      : ""
                                                                  }
                                                                >
                                                                  {
                                                                    user_data.first_name
                                                                  }
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                      </select>
                                                    </div>
                                                  </td>
                                                  <td className="custom_task_name">
                                                    <div className="cutom_plus">
                                                      <img src="../Assets/img/plus.png"></img>
                                                      <input
                                                      disabled={
                                                         eventTypeOf ==
                                                         "close_event"
                                                           ? true
                                                           : false
                                                       }
                                                        type="date"
                                                        id="date"
                                                        defaultValue={
                                                          task_data.due_date?.split(
                                                            "T"
                                                          )[0]
                                                        }
                                                        className="due_date"
                                                        style={{
                                                          border: "none",
                                                        }}
                                                        min={todayDate}
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </>
              );
            })}
        </div>
      </section>
    </div>
  );
};
export default CheckList;
