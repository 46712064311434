import React from 'react';
import MainHeader from '../components/shared/MainHeader';
import ListGroup from 'react-bootstrap/ListGroup';
import Footer from "../components/shared/Footer";

const PrivacyPolicy = () => {
    return (
        <>
            <MainHeader />
            <section>
                <div className='about_container shadow'>

                    <h2 className='privacy_policy'> Privacy Policy</h2>
                    <div>
                        <h5>Article 1- DEFINITIONS: </h5>
                        <ol type='A'>
                            <li>
                                <p className='About_text'> 
                                    <b> APPLICABLE WEBSITE:</b>
                                    This Privacy Policy will refer to and be applicable to the Website listed above, which shall hereinafter be referred to as "Website." Any listed Website may also refer to a corresponding mobile application, should one be currently in use or hereinafter developed. 
                                </p>
                            </li>
                            <li>
                                <p className='About_text'> 
                                    <b> EFFECTIVE DATE:</b>
                                    "Effective Date" means the date this Privacy Policy comes into force and effect.
                                </p>
                            </li>
                            <li>
                                <p className='About_text'> 
                                    <b> PARTIES:</b>
                                    The parties to this privacy policy are the following data controller: Lyric Champ LLC (DBA Easy Events LLC) ("Data Controller") and you, as the user of this Website. Hereinafter, the parties will individually be referred to as "Party" and collectively as "Parties."                             
                                </p>
                            </li>
                            <li>
                                <p className='About_text'> 
                                    <b> DATA CONTROLLER:</b>
                                    Data Controller is the publisher, owner, and operator of the Website and is the Party responsible for the collection of information described herein. Data Controller shall be referred to either by Data Controller's name or "Data Controller," as listed above. If Data Controller or Data Controller's property shall be referred to through first-person pronouns, it shall be through the use of the following: us, we, our, ours, etc.                                
                                </p>
                            </li>
                            <li>
                                <p className='About_text'> 
                                    <b> YOU:</b>
                                    Should you agree to this Privacy Policy and continue your use of the Website, you will be referred to herein as either you, the user, or if any second-person pronouns are required and applicable, such pronouns as 'your", "yours", etc.
                                </p>
                            </li>

                            <li>
                                <p className='About_text'> 
                                    <b> SERVICES:</b>
                                    "Services" means any services that we make available for sale on the Website.
                                </p>
                            </li>

                            <li>
                                <p className='About_text'> 
                                    <b>  PERSONAL DATA:</b>
                                    "Personal DATA" means personal data and information that we obtain from you in connection with your use of the Website that is capable of identifying you in any manner.
                                </p>
                            </li>
                        </ol>

                    </div>

                    <div>
                        <h5>Article 2-GENERAL INFORMATION:  </h5>

                        <p className='About_text'> 
                        This privacy policy (hereinafter "Privacy Policy") describes how we collect and use the Personal Data that we receive about you, as well as your rights in relation to that Personal Data, when you visit our Website or use our Services.                         
                        
                        </p>

                        <p className='About_text'> 
                        This Privacy Policy does not cover any information that we may receive about you through sources other than the use of our Website. The Website may link out to other websites or mobile applications, but this Privacy Policy does not and will not apply to any of those linked websites or applications.                         
                        </p>


                        <p className='About_text'> 
                        We are committed to the protection of your privacy while you use our Website.                         
                        </p>

                        <p className='About_text'> 
                        By continuing to use our Website, you acknowledge that you have had the chance to review and consider this Privacy Policy, and you acknowledge that you agree to it. This means that you also consent to the use of your information and the method of disclosure as described in this Privacy Policy. If you do not understand the Privacy Policy or do not agree to it, then you agree to immediately cease your use of our Website.                         
                        </p>
                    </div>

                    <div>
                        <h5>Article 3-CONTACT AND DATA PROTECTION OFFICER:   </h5>

                        <p className='About_text'> 
                        The Party responsible for the processing of your personal data is as follows: Lyric Champ LLC (DBA Easy Events LLC). The Data Controller may be contacted as follows:                         
                        </p>

                        <p className='About_text'> 
                        privacy@easyeevents.com 
                        </p>

                        <p className='About_text'> 
                        The Data Controller and operator of the Website are one and the same.                         
                        </p>

                        <p className='About_text'> 
                        The Data Protection Officer is as follows: Ian Atwater. The Data Protection Officer may be contacted as follows:                                                
                        </p>

                        <p className='About_text'> 
                        privacy@easyeevents.com 
                        </p>

                    </div>

                    <div>
                        <h5>Article 4-LOCATION:   </h5>

                        <p className='About_text'> 
                        Please be advised the data processing activities take place in the United States, outside the European Economic Area. Data may also be transferred to companies within the United States, but will only be done so in a manner that complies with the EU's General Data Protection Regulation or GDPR. The location where the data processing activities take place is as follows:                         
                        
                        </p>

                        <p className='About_text'> 
                        Remote Data Centers, <br/>
                        United States of America                         .
                        </p>
                       
                    </div>

                    <div>
                        <h5>Article 6-MODIFICATIONS AND REVISIONS:    </h5>

                        <p className='About_text'> 
                        We reserve the right to modify, revise, or otherwise amend this Privacy Policy at any time and in any manner. If we do so, however, we will notify you and obtain your consent to the change in processing. Unless we specifically obtain your consent, any changes to the Privacy Policy will only impact the information collected on or after the date of the change. It is also your responsibility to periodically check this page for any such modification, revision or amendment.                         
                        </p>

                        <p className='About_text'> 
                        Remote Data Centers, <br/>
                        United States of America.
                        </p>
                       
                    </div>



                    <ListGroup variant="flush">
                        <ListGroup.Item>Point 1 </ListGroup.Item>
                        <ListGroup.Item>Point 2</ListGroup.Item>
                        <ListGroup.Item>Point 3</ListGroup.Item>
                        <ListGroup.Item>Point 4</ListGroup.Item>
                    </ListGroup>

                </div>



            </section>

            <Footer />
        </>
    );
}

export default PrivacyPolicy;
