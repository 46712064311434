import React, { useEffect, useState } from 'react'; 
import {BrowserRouter,Switch,Route,Link,useLocation, useHistory } from "react-router-dom";

const Header = (props) => {
const [url, setUrl] = useState(null)
return(
   <div> 
      <header>  
         <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
               <Link to="/" className={"navbar-brand" + (url === "/" ?" active" : "") }><img src="Assets/img/logo.png" /></Link> 
            </div>
         </nav>
      </header> 
   </div>
)}
export default Header;