import React, { useState, useRef, useEffect } from "react";
import "../App/public/css/blogs.css";
import MainHeader from "../components/shared/MainHeader";
import Footer from "../components/shared/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import get_url from "../events/common/ApiCaller";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

const BlogListings = () => {
  const [blogData, setBlogData] = useState([]);
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);

  const getBlogData = async () => {
    setIsLoaderDisplay(true);
    await axios.get(`${get_url.local_url}get-blog-data`).then((response) => {
      const get_user_detail = response.data.data;
      setBlogData(get_user_detail);
      setIsLoaderDisplay(false);
    });
  };
  useEffect(() => {
    getBlogData();
  }, []);
  console.log("blogData", blogData);
  return (
    <>
      {isLoaderDisplay ? (
        <div className="overlay">
          <div className="center_dots">
            <ThreeDots height="80" width="80" radius="9" color="#0d6efd" />
          </div>
        </div>
      ) : (
        ""
      )}
      <MainHeader />
      <div class="container Blog_page_container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="section-title mb-2 pb-2">
              <h4 class="title mb-2">Explore Our Blogs</h4>
              {/* <div className='Divider' /> */}
              <p class="para-desc mx-auto mb-0">
                {" "}
                Our Blogs will help you to manage easyevents better
              </p>
            </div>
          </div>
        </div>

        <div class="row blog_list_outer">
          {blogData?.length > 0 &&
            blogData?.map((blog_data) => {
              return (
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                  <div class="blog-post border">
                    <div class="blog-img d-block overflow-hidden position-relative">
                      <img
                        src={
                          get_url.local_url +
                          `upload/` +
                          blog_data.blog_img_link
                        }
                        class="rounded-top_Blog"
                        alt=""
                      />
                      <div class="overlay rounded-top_Blog bg-dark"></div>
                      <div class="post-meta">
                        <Link to="#" class="read-more">
                          Read More <i class="mdi mdi-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                    <div class="content p-3">
                      <small class="text-muted p float-right">
                      {moment(blog_data?.createdAt).format("DD/MM/YYYY")}
                      </small>
                      {/* <small><Link to="#" class="text_category">Marketing</Link></small> */}
                      <h4 class="mt-2 Blog_title_outer">
                        <Link to={blog_data._id} class="title Blog_title">
                          {blog_data.blog_title}
                        </Link>
                      </h4>
                      <p class="text_muted_2 mt-2">
                    {blog_data.blog_description.replace(/<[^>]+>/g, "")}
                      </p>
                      <div class="pt-3 mt-3 border-top d-flex">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar1.png"
                          class="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow a_avatar"
                          alt=""
                        />
                        <div class="author mt-2">
                          <h6 class="mb-0">
                            <Link to="#" class="text-dark A_name">
                              Admin
                            </Link>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {/* 
        <nav aria-label="Page navigation blog_Page_navigation example">
            <ul class="pagination blog_pagination justify-content-center">
                <li class="page-item disabled">
                <Link to="#" class="page-link">Previous</Link>
                </li>
                <li class="page-item"><Link class="page-link" to="#">1</Link></li>
                <li class="page-item"><Link class="page-link" to="#">2</Link></li>
                <li class="page-item"><Link class="page-link" to="#">3</Link></li>
                <li class="page-item">
                <Link class="page-link" to="#">Next</Link>
                </li>
            </ul>
        </nav> */}
      <Footer />
    </>
  );
};

export default BlogListings;
