import React, { useState, useRef, useEffect, Component } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Layout/AdminSidebar';
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";


const Index = () => {

    const [data, setData] = useState([])

    const handleDeleteBlog = async (row) => {
        var url = `${get_url.local_url}delete-blog/`+row._id;     
        const { data } = await axios.get(url);
        if(data.status){
          alert(data.message);
          getAllBlogs()
        }
      }

    const columns = [
        {
          name: '#Sno.',
          cell: (row, index) => index + 1  //RDT provides index by default
        },
        {
          name: 'Blog Title',
          selector: row => row.blog_title, 
          sortable: true,
        },
        {
          name: 'Blog Image',
          selector: row => <img src={get_url.local_url+`upload/`+row.blog_img_link}  style={{height:'100px', width: '100px'}}/>,   
          sortable: true,
        },  
        {
            name: 'Blog Description',
            selector: row => row.blog_description,   
            sortable: true,
        },        
        {
          name: 'Delete',
          selector: (row, index) => <button  className=" btn action_btn" onClick={() => handleDeleteBlog(row)} ><i class="fas fa-trash"></i></button>
        },
      ];
   
    const getAllBlogs = async() => {
        const { data } = await axios.get(`${get_url.local_url}get_blogs`);
        setData(data.data)
    }

    useEffect(() => {
        getAllBlogs();
    }, [ ]);
    return (
        <>
        <AdminHeader />

            <div className="container-fluid">
                <div className="row">
                    <AdminSideBar />
                    <main className="col-md-9 ms-sm-auto col-lg-10 "> 
                        <div className='events_list Page_box'>
                            <div className="event_list_head mb-3 p-2">
                            <div className='eve_list_heading'>
                                <h5>Blogs</h5>
                            </div>
                            <hr className="dropdown-divider" />
                            <div className='see_all'>
                                <div> 
                                    <Link variant="primary" to="/AddBlog" className='btn btn-light'>Create new blog</Link>                                
                                </div>
                            </div>
                            </div>            
                        </div>
                        <div className='events_list Page_box_subscription'>
                            <DataTable
                                title="Blogs"
                                columns={columns}
                                data={data}
                                pagination
                                highlightOnHover
                            />            
                        </div>
                    </main>
                </div>
            </div>
                
            
        </>
    );
}

export default Index;