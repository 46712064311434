// import axios from "axios";
// const api = axios.create({
//   //baseURL: "https://easyevents.app:5080/" 
//   baseURL: "http://localhost:5080/"
// });
// export { api }   

let get_url = {
    //local_url : "http://localhost:5080/",
    //local_url : "https://easyevents.itechnolabs.tech:5080/",
    local_url : "https://easyevents.app:5080/",
    
    local_front_url : "https://easyevents.app/",
}
module.exports = get_url