import React, { useState, useRef, useEffect } from 'react';
import '../App/public/css/blogs.css';
import MainHeader from '../components/shared/MainHeader';
import Footer from "../components/shared/Footer"
import axios from "axios";
import get_url  from '../events/common/ApiCaller';
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom';

const BlogDetails = () => {

    const [blogData, setBlogData] = useState([]);
    const [particularBlogData, setParticularBlogData] = useState([]);
    const[isLoaderDisplay,setIsLoaderDisplay] = useState(false);

    const getBlogData = async() => { 
        setIsLoaderDisplay(true)
        await axios.get(`${get_url.local_url}get-blog-data`).then((response) => {
            const get_user_detail  = response.data.data
            setBlogData(get_user_detail)
        })
    }
    const getSelectedBlogData = async(_id) => { 
        await axios.get(`${get_url.local_url}get-selected-blog-data/${_id}`).then((response) => {
            const get_user_detail  = response.data.data[0]

console.log(get_user_detail,"datat")
            setParticularBlogData(get_user_detail) 
            setIsLoaderDisplay(false)
        })
    }
    useEffect(() => {  
        let _id = window.location.href.split('/')[4]
        getBlogData()  
        getSelectedBlogData(_id)  
    },[]); 
    return (
       <>
        {isLoaderDisplay? <div className='overlay'><div className="center_dots"><ThreeDots height="80" width="80" radius="9" color="#0d6efd" /></div></div>:''}
        <MainHeader/> 
        <div> 
            <div class="blog-single gray-bg">
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-lg-8 m-15px-tb">
                            <article class="article">
                                <div class="article-img">
                                    <img src={
                          get_url.local_url +
                          `upload/` +
                          particularBlogData.blog_img_link
                        } title="" alt="" />
                                </div>
                                <div class="article-title">
                                    {/* <h6><a href="#">Lifestyle</a></h6> */}
                                    <h2>{particularBlogData.blog_title}</h2>
                                    <div class="media">
                                        <div class="avatar">
                                            <img src='https://bootdey.com/img/Content/avatar/avatar1.png' title="" alt="" />
                                        </div>
                                        <div class="media-body">
                                            <label>Admin</label>
                                            {/* <span>26 FEB 2020</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="article-content">
                    {particularBlogData?.blog_description?.replace(/<[^>]+>/g, "")}

                                    
                                </div>
                                {/* <div class="nav tag-cloud">
                                    <a href="#">Design</a>
                                    <a href="#">Development</a>
                                    <a href="#">Travel</a>
                                    <a href="#">Web Design</a>
                                    <a href="#">Marketing</a>
                                    <a href="#">Research</a>
                                    <a href="#">Managment</a>
                                </div> */}
                            </article>
                            {/* <div class="contact-form article-comment">
                                <h4>Leave a Reply</h4>
                                <form id="contact-form" method="POST">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input name="Name" id="name" placeholder="Name *" class="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input name="Email" id="email" placeholder="Email *" class="form-control" type="email" />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" id="message" placeholder="Your message *" rows="4" class="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="send">
                                                <button class="px-btn theme"><span>Submit</span> <i class="arrow"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                        <div class="col-lg-4 m-15px-tb blog-aside">
                            {/* <!-- Author --> */}
                            {/* <div class="widget widget-author">
                                <div class="widget-title">
                                    <h3>Author</h3>
                                </div>
                                <div class="widget-body">
                                    <div class="media align-items-center">
                                        <div class="avatar">
                                            <img src="https://bootdey.com/img/Content/avatar/avatar6.png" title="" alt="" />
                                        </div>
                                        <div class="media-body">
                                            <h6>Hello, I'm<br/> Rachel Roth</h6>
                                        </div>
                                    </div>
                                    <p>I design and develop services for customers of all sizes, specializing in creating stylish, modern websites, web services and online stores</p>
                                </div>
                            </div> */}
                            {/* <!-- End Author --> */}
                            {/* <!-- Trending Post --> */}
                            {/* <div class="widget widget-post">
                                <div class="widget-title">
                                    <h3>Trending Now</h3>
                                </div>
                                <div class="widget-body">

                                </div>
                            </div> */}
                            {/* <!-- End Trending Post --> */}
                            {/* <!-- Latest Post --> */}
                            <div class="widget widget-latest-post">
                                <div class="widget-title">
                                    <h3>Latest Post</h3>
                                </div>
                                <div class="widget-body">
                                { blogData?.length>0 && blogData?.map((blog_data)=>{
                                    return(
                                        <div class="latest-post-aside media">
                                            <div class="lpa-left media-body">
                                                <div class="lpa-title">
                                                    <h5  ><Link onClick={"location.reload()"} to={'/BlogListings/'+blog_data._id } >{blog_data.blog_title}</Link></h5>
                                                </div>
                                                <div class="lpa-meta">
                                                    {/* <a class="name" href="#">
                                                        Admin
                                                    </a> */}
                                                    {/* <a class="date" href="#">
                                                        26 FEB 2020
                                                    </a> */}
                                                </div>
                                            </div>
                                            <div class="lpa-right">
                                                <Link to={'/BlogListings/'+blog_data.blog_slug}>
                                                    <img src={blog_data.blog_img_link} title="" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    )})}
                                </div>
                            </div>
                            {/* <!-- End Latest Post --> */}
                            {/* <!-- widget Tags --> */}
                            {/* <div class="widget widget-tags">
                                <div class="widget-title">
                                    <h3>Latest Tags</h3>
                                </div>
                                <div class="widget-body">
                                    <div class="nav tag-cloud">
                                        <a href="#">Design</a>
                                        <a href="#">Development</a>
                                        <a href="#">Travel</a>
                                        <a href="#">Web Design</a>
                                        <a href="#">Marketing</a>
                                        <a href="#">Research</a>
                                        <a href="#">Managment</a>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!-- End widget Tags --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/> 
       </>
    )
}

export default BlogDetails;
