import React,{useEffect, useState} from 'react'; 
import '../../SuperAdmin/css/style.css';
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Layout/AdminSidebar';
import Button from 'react-bootstrap/Button';
import SubscriptionModal from './addSubcriptionModal';
import EditSusbcription from './editModal';
import DataTable from 'react-data-table-component';
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";
import { toast } from "react-toastify";

 const Index = () => 
 {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
  const [data, setData] = useState([])
  const [editSub, setEditSub] = useState(false)
  const handleEditClose = () => setEditSub(false);
  const handleEditShow = () => setEditSub(true); 
  const [newData, setNewData] = useState(false);
  const [plan, setPlan ] = useState({})

  const handleEditSubscription = (data) => {  
    setPlan(data)
    setEditSub(true)
  }

  const handleDeleteSubscription = async (row) => {
    var url = `${get_url.local_url}delete-subscription/`+row._id;     
    const { data } = await axios.get(url);
    if(data.status){
      toast.success(data.message);
      getAllSubscription()
    }
  }

  

  const columns = [
                    {
                      name: '#Sno.',
                      cell: (row, index) => index + 1  //RDT provides index by default
                    },
                    {
                      name: 'Plan Name',
                      selector: row => row.plan_name, 
                      sortable: true,
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Plan Amount',
                      selector: row => row.plan_amount,   
                      sortable: true,
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Events',
                      selector: row => row.create_events,                      
                      sortable: true,
                      maxWidth: 'auto',
                    },
                    {
                      name: 'Team Member',
                      selector: row => row.team_member,
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Role Access',
                      selector: row => row.role_based_access == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Checklist',
                      selector: row => row.automated_checklist == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                    },
                    {
                      name: 'Budget Tool',
                      selector: row => row.budget == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Checklist Reminder',
                      selector: row => row.checklist_reminder == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                      width: '10%'
                    },
                    {
                      name: 'CRM',
                      selector: row => row.is_crm == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                    },
                    {
                      name: 'Email Support',
                      selector: row => row.email_support == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Chat Support',
                      selector: row => row.chat_support == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Live Training',
                      selector: row => row.training == 1 ? 'Yes' : 'No',
                      maxWidth: 'auto',
                      width: '8%'
                    },
                    {
                      name: 'Additional Feature',
                      selector: row => row.additional_feature == '' ? 'No Additional Feature' : row.additional_feature,
                      maxWidth: 'auto',
                      width: '11%'
                    },
                    // {
                    //   name: 'Edit',
                    //   selector: (row, index) =>  <button  className=" btn action_btn" onClick={() => handleEditSubscription(row)} ><i class="fas fa-edit"></i></button> 
                    // },
                    // {
                    //   name: 'Delete',
                    //   selector: (row, index) => <button  className=" btn action_btn" onClick={() => handleDeleteSubscription(row)} ><i class="fas fa-trash"></i></button>
                    // },
                  ];

  const getAllSubscription = async() => {
    const { data } = await axios.get(`${get_url.local_url}get_subscription`);
    setData(data.data)
  }

  useEffect(() => {
    getAllSubscription();
  }, [ ]);

  return ( 
    <>
    <AdminHeader />
    <div className="container-fluid">
      <div className="row">
       <AdminSideBar />
        <main className="col-md-9 ms-sm-auto col-lg-10 "> 
         <div className='events_list'>
            <div className="event_list_head su_btn_box">
              
                <div className='Subscription_btn'> 
                  <Button variant="primary" onClick={handleShow}>Add Subcription Plan</Button>
                  {/*
                  <Link to="#" variant="primary">See All</Link> */}
                </div>
            </div>            
          </div>
          <div className='events_list Page_box_subscription'>
            <DataTable
              title="Subscription"
              columns={columns}
              data={data}
              pagination
              highlightOnHover
            />            
          </div>
        </main>
      </div>
    </div>
    <SubscriptionModal show={show} setShow={setShow} getAllSubscription={getAllSubscription} handleClose={handleClose}/> 
    <EditSusbcription editSub={editSub} plan={plan} setEditSub={setEditSub} handleEditClose={handleEditClose}/> 
  </> 
  ); 
  } 
  export default Index;