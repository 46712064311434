import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Link, useNavigate} from 'react-router-dom';
import  Header  from './common/Header'; 
import  get_url  from './common/ApiCaller';
import axios from "axios";
import $ from 'jquery'; 

import {useHistory} from 'react-router-dom';

const Step6 = (props) => {
    const navigate = useNavigate(); 
   
    const [isRadioSelect, setIsRadioSelect] = useState(false);
    const [isSelectedValue, setisSelectedValue] = useState("0");
    const [eventSize, setEventSize] = useState(['1-20','21-50','50+']);

    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "event_id" : localStorage.getItem('event_id'),
        "user_role" : localStorage.getItem('role')
    }}

    const handleChange = (e) => {
		setIsRadioSelect(true)
        localStorage.setItem('step5', e.target.value);
        props.setSelectedEventsEstimates(e.target.value);
        setisSelectedValue(e.target.value)
	};

    const handleRadioButton  = (index,event_mode) => {
        //$(`.even_box1`).removeClass('active-border')
        //$(`#div_id_${index}`).addClass('active-border')  
        $(`.radio_data`).attr("checked", false);
        $(`#checkbox_id_${event_mode}`).attr("checked", true); 
    } 
    const submitForm = ()=>{
        let get_event_size = $('input[name="event_size"]:checked').val() 
        if(get_event_size == undefined){
            alert('Please select event size first.')
        }else{
            let event_data = {
                event_size : get_event_size,
                event_id : localStorage.getItem("event_id")
            }
            axios.post(`${get_url.local_url}event-all-form`, event_data, header).then((response) => {  
                navigate('/step7');
            })
        }
    }
    const fetchEventData = async() => {
        let event_id = localStorage.getItem("event_id")
        if (null == event_id) {
            navigate('/PricingPlans')
        }
        await axios.get(`${get_url.local_url}event-data/${event_id}`).then((response) => {
            const get_event_data  = response.data[0]  
            if ('undefined' === typeof get_event_data) {
                navigate('/Dashboard')
            }
            if(get_event_data.event_size != undefined){
                $(`#checkbox_id_${get_event_data?.event_size?.replace('+','')}`).attr("checked", true);
            }
        })
    };
    useEffect(() => {
        fetchEventData();
    },[]) 

    return (
            <>
            <Header/>
                <div className="footstep-outer">
                    <div className="foot-step shadow-lg"> Step 6 of 7</div>
                </div>
                <section className="planing_outer"> 
                    <div className="container">
                    <Link to="/step5">
                {" "}
                <i
                  style={{ fontSize: "50px" }}
                  class="fa-sharp fa-solid fa-arrow-left"
                ></i>
              </Link>
                        <div className="inner_heading">
                            <h4>How big is your event?</h4>
                        </div>   
                        <div className="row">  
                            <div className="estimate-5">
                                <div className="text_outer"># of Estimated Attendees</div>
                                <div className="row">
                                    {eventSize.length > 0 && eventSize.map((event, index)=>(
                                        <div className="col-lg-4 col-md-4" key={index}>
                                            <div className="even_box1" id={`div_id_${index}`} onClick={(e) => { handleRadioButton(index,event.replace('+',''))}}>
                                                <label className="radiocontainer">
                                                    <input type="radio" className="ischeck prev_valued radio_data" value={event} name="event_size" id={`checkbox_id_${event.replace('+','')}`} /> 
                                                    <span className="checkmark"></span> 
                                                    <h3>{event}</h3>
                                                </label>
                                            </div>
                                        </div> 
                                    ))}
                                </div>   	
                                <div className="btn_outer no_edit later3">
                                    <p className="p_text">You can always edit it later</p>	
                                    <button type='button' className='btn btn-primary' onClick={(e)=>{submitForm()}}> Continue </button>
                                </div>
                            </div>  
                        </div>
                    </div> 
	            </section>
            </>
        )
}
export default Step6;