import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Link, useNavigate} from 'react-router-dom';
import  Header  from './common/Header'; 
import  get_url  from './common/ApiCaller';
import axios from "axios";
import $ from 'jquery';

const Step2 = (props) => {
    const navigate = useNavigate();
    const [eventData, setEventData] = useState({}); 
    const [eventMode, setEventMode] = useState(['In Person','Online','Hybrid']);
    const [items, setItems] = useState([]);
    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "event_id" : localStorage.getItem('event_id'),
        "user_role" : localStorage.getItem('role')
    }}
    
    const handleRadioButton  = (index,event_mode) => {
        // $(`.even_box1`).removeClass('active-border')
        // $(`#div_id_${index}`).addClass('active-border')  
        $(`.radio_data`).attr("checked", false);
        $(`#checkbox_id_${event_mode}`).attr("checked", true); 
    } 
    const submitForm = ()=>{
        let checked_radio_id = $('input[name="event_platform"]:checked').val() 
        if(checked_radio_id == undefined){
            alert('Please select any event mode first.')
        }else{
            let event_data = {
                event_mode : checked_radio_id,
                event_id : localStorage.getItem("event_id")
            }
            axios.post(`${get_url.local_url}event-all-form`, event_data, header).then((response) => { 
                console.log('response',response)
                navigate('/step3');
            })
        }
    }
    const fetchEventData = async() => { 
        let event_id = localStorage.getItem("event_id")
        
        if (null == event_id) {
            navigate('/PricingPlans')
        }
        await axios.get(`${get_url.local_url}event-data/${event_id}`).then((response) => {
            const get_event_data  = response.data[0]
            if ('undefined' === typeof get_event_data) {
                navigate('/Dashboard')
            }
            setEventData(get_event_data) 
            $(`#checkbox_id_${get_event_data?.event_platform}`).attr("checked", true);
        })
    };
    useEffect(() => {
        fetchEventData();
    },[]) 
    useEffect(() => {
        const items = localStorage.getItem("selected_category");
    
        setItems(items);
      }, []);
    return (
            <>
             <Header/>
                <div className="footstep-outer">
                    <div className="foot-step shadow-lg"> Step 2 of 7</div>
                </div>

                <section className="planing_outer"> 
                        <div className="container">
                        <Link to={items ? "/EditStep1" : "/step1"}>
              {" "}
              <i
                style={{ fontSize: "50px" }}
                class="fa-sharp fa-solid fa-arrow-left"
              ></i>
            </Link>
                            <div className="inner_heading">
                                <h4>What is your event mode?</h4> 
                            </div>   
                            <div className="row">  
                        <div className="estimate-5">
                            <div className="text_outer">
                            {/* # of Estimated Attendees */}
                            </div>
                            <div className="row">
                                {eventMode.length > 0 && eventMode.map((event, index)=>(
                                    <div className="col-lg-4 col-md-4" key={index}>
                                        <div className="even_box1" id={`div_id_${index}`} onClick={(e) => { handleRadioButton(index,event.toLowerCase().replace(' ','_'))}}>
                                            <label className="radiocontainer">
                                            <input type="radio" className="ischeck prev_valued radio_data" value={event.toLowerCase().replace(' ','_')} name="event_platform" id={`checkbox_id_${event.toLowerCase().replace(' ','_')}`} /> 
                                                <span className="checkmark"></span> 
                                                <h3>{event}</h3>
                                            </label>
                                        </div>
                                    </div> 
                                ))}  
                            </div>   
                            <div className="p_text">You can always edit it later</div>			
                            <div className="btn_outer">
                                <button type='button' className='btn btn-primary' onClick={(e)=>{submitForm()}}> Continue </button>
                            </div>
                        </div>  
                            </div>
                        </div> 
	            </section>
            </>
        )
}
export default Step2;