import React, { useState, useRef, useEffect } from "react";
import EventDetails from "../events/EventDetails";
import SideBar from "../events/SideBar";
import { Link } from "react-router-dom";
import get_url from "./common/ApiCaller";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import ModalDialog from "react-bootstrap/ModalDialog";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import $, { error } from "jquery";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment/moment";
const Budgets = (props) => {
  window.scrollTo(0, 0);
  const [showModal, setShow] = useState(false);
  const [showEditModal, setEditModalShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditModalClose = () => setEditModalShow(false);
  const [categoryList, setCategoryList] = useState("");
  const [checkListCategory, setCheckListCategory] = useState([]);
  const [checkListCategoryid, setcheckListCategoryid] = useState("");
  const [taskList, setTaskList] = useState([]);
  const [budgetList, setBudgetList] = useState("");
  const [remaningBudget, setRemaningBudget] = useState("");
  const [expenseSummary, setExpenseSummary] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [task, setTask] = useState("");
  const [isEnded, setIsEnded] = useState(false);
  const [count, setCount] = useState(false);
  const [totalBudget, setTotalBudget] = useState(0);
  const [reeemaining, setReeemaining] = useState(0);
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [deleteAmount, setDeleteAmount] = useState(0);
  const [defaultBudgetList, setDefaultBudgetList] = useState(0);
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const [getBudgetDetail, setGetBudgetDetail] = useState({});
  const eventTypeOf = localStorage.getItem("event_type");

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
    },
  };

  const delete_budget = async (budget_id, amount) => {
    if (window.confirm("Are you sure to delete this expense summary.")) {
      await axios
        .delete(`${get_url.local_url}delete-budget/${budget_id}`, header)
        .then((response) => {
          setReeemaining(reeemaining + parseInt(amount));
          setDeleteAmount(parseInt(amount));
          getBudget();
          //$(`#budget_id_${budget_id}`).hide(1000)
          setIsLoaderDisplay(false);
          // alert('Budget deleted successfully.');
        })
        .catch((error) => console.log(error));
    } else {
      setIsLoaderDisplay(false);
    }
  };

  const getSingleBudget = async (budgetId) => {
    if ("undefined" === typeof budgetId || "" === budgetId) {
      return false;
    }
    await axios
      .delete(`${get_url.local_url}edit-budget/${budgetId}`, header)
      .then((response) => {
        if (200 !== response.data.status) {
          alert(response.data.message);
          return false;
        }
        setEditModalShow(true);
        setGetBudgetDetail(response.data.data);

        console.log(response, "SSSS");
        // setReeemaining(reeemaining + parseInt(amount))
        // setDeleteAmount(parseInt(amount))
        // getBudget()
        // //$(`#budget_id_${budget_id}`).hide(1000)
        // setIsLoaderDisplay(false)
        // // alert('Budget deleted successfully.');
      })
      .catch((error) => console.log(error));
  };

  let expenseBudget = totalBudget;

  const addExpense = async () => {
    let get_expense_summary = $(`#expensSummary`).val();
    let get_expense_amount = $(`#expenseAmount`).val();
    let get_payment_date = $(`#formPhoneNumber`).val();
    let get_category = $(`#formGridState`).val();
    let get_checklist_item = $(`#formNotes`).val();

    if (get_expense_summary == "" || typeof get_expense_summary == undefined) {
      alert("Please add expense summary.");
    } else if (
      get_expense_amount == "" ||
      typeof get_expense_amount == undefined
    ) {
      alert("Please add expense amount.");
    } else if (get_payment_date == "" || typeof get_payment_date == undefined) {
      alert("Please add payment date.");
    }
    // else if(get_category == '' || typeof get_category == undefined){
    //    alert('Please select category.')
    // }else if(get_checklist_item == '' || typeof get_checklist_item == undefined){
    //    alert('Please select checklist item.')
    // }
    else {
      let remaining_budget_amount =
        parseFloat(reeemaining) - parseFloat(get_expense_amount);

      if (totalBudget - expenseAmount - get_expense_amount < 0) {
        alert("Please add budget.");
        return;
      }
      let budget_data = {
        expense_summary: get_expense_summary,
        expense_amount: get_expense_amount,
        payment_date: get_payment_date,
        category: "undefined" === typeof get_category ? "" : get_category,
        checklist_item:
          "undefined" === typeof get_checklist_item ? "" : get_checklist_item,
        remaining_budget: remaining_budget_amount,
      };
      setIsLoaderDisplay(true);
      await axios
        .post(`${get_url.local_url}add-budget-data`, budget_data, header)
        .then((response) => {
          $(".contact_field_for_empty").find("input:text").val("");
          $("[aria-label=Close]").trigger({ type: "click" });
          setReeemaining(totalBudget - expenseAmount);
          // alert('Budget added successfully.')
          getBudget();
        })
        .catch((error) => console.log(error));
    }
  };

  const getBudget = async () => {
    setIsLoaderDisplay(true);
    await axios
      .get(`${get_url.local_url}get-budget`, header)
      .then((response) => {
        const all_contact_list_data = response.data.data;
        var expense_amount = 0;
        all_contact_list_data?.map((expense_summary, k) => {
          expense_amount += parseInt(expense_summary.expense_amount);
        });

        setExpenseAmount(expense_amount);
        setBudgetList(all_contact_list_data);
        setIsLoaderDisplay(false);
      })
      .catch((error) => console.log(error));
  };

  const editExpense = async (budgetId) => {
    let get_expense_summary = $(`#editexpensSummary`).val();
    let get_expense_amount = $(`#editexpenseAmount`).val();
    let get_payment_date = $(`#editformPhoneNumber`).val();
    let get_category = $(`#editformGridState`).val();
    let get_checklist_item = $(`#editformNotes`).val();

    if (get_expense_summary == "" || typeof get_expense_summary == undefined) {
      alert("Please add expense summary.");
    } else if (
      get_expense_amount == "" ||
      typeof get_expense_amount == undefined
    ) {
      alert("Please add expense amount.");
    } else if (get_payment_date == "" || typeof get_payment_date == undefined) {
      alert("Please add payment date.");
    }
    // else if(get_category == '' || typeof get_category == undefined){
    //    alert('Please select category.')
    // }else if(get_checklist_item == '' || typeof get_checklist_item == undefined){
    //    alert('Please select checklist item.')
    // }
    else {
      let finalExpenseAmount =
        expenseAmount - parseFloat(getBudgetDetail.expense_amount);

      let remaining_budget_amount =
        parseFloat(reeemaining) - parseFloat(get_expense_amount);

      if (totalBudget - finalExpenseAmount - get_expense_amount < 0) {
        alert("Please add budget.");
        return;
      }

      let budget_data = {
        expense_summary: get_expense_summary,
        expense_amount: get_expense_amount,
        payment_date: get_payment_date,
        category: "undefined" === typeof get_category ? "" : get_category,
        checklist_item:
          "undefined" === typeof get_checklist_item ? "" : get_checklist_item,
        remaining_budget: remaining_budget_amount,
      };
      setIsLoaderDisplay(true);
      await axios
        .post(
          `${get_url.local_url}update-budget/` + budgetId,
          budget_data,
          header
        )
        .then((response) => {
          $(".contact_field_for_empty").find("input:text").val("");
          handleEditModalClose();
          setReeemaining(totalBudget - expenseAmount);
          // alert('Budget added successfully.')
          getBudget();
        })
        .catch((error) => console.log(error));
    }
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    await axios
      .get(`${get_url.local_url}event-data/${event_id}`, header)
      .then((response) => {
        const get_event_data = response.data[0];
        setTotalBudget(parseInt(get_event_data.budget));
      });
  };
  const checklistCategory = async () => {
    await axios
      .get(`${get_url.local_url}list-categories`, header)
      .then((response) => {
        const get_check_list_data = response.data.data;
        setCheckListCategory(get_check_list_data);
      });
  };
  const taskListData = async () => {
    await axios
      .get(`${get_url.local_url}task-list`, header)
      .then((response) => {
        const get_task_data = response.data;
        setTaskList(get_task_data);
      });
  };

  const [getUpdateData, setgetUpdateData] = useState([]);

  const data = checkListCategory.map((item, index) => {
    if (
      localStorage.getItem("selected_category").includes(item._id) &&
      !getUpdateData.some(function (o) {
        return o["id"] === item._id;
      })
    ) {
      getUpdateData.push({ id: item._id, name: item.name });
    }
  });

  useEffect(() => {
    getBudget();
    fetchEventData();
    checklistCategory();
    taskListData();
  }, [isEnded]);

  setTimeout(function () {
    setReeemaining(totalBudget - expenseAmount);
  }, 1000);

  return (
    <>
      {isLoaderDisplay ? (
        <div className="overlay">
          <div className="center_dots">
            <ThreeDots height="80" width="80" radius="9" color="#0d6efd" />
          </div>
        </div>
      ) : (
        ""
      )}

      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>
      <section className="check_list">
        <SideBar />
        <div className="check_right">
          <div className="row d-flex check_heading">
            <div className="col-lg-9 col-md-6">
              <div className="assign_outer">
                <h2>Budget</h2>
                <p>Use this page to see expenses by day</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              {/* <div className="check_list62">  
                        <div className="dropdown filter-btn">
                           <button className="btn btn-view-by dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="Assets/img/list.png" />
                           </button>  
                        </div>
                     </div> */}
            </div>
          </div>
          <hr />
          <div className=" budget-balance listy9">
            <div className=" budget-balance-total">
              <p className="balance-label">Total Event Budget: </p>
              <input
                className="form-control input-boxes"
                value={"$ " + totalBudget}
                readOnly
              />
            </div>
            <div className=" budget-balance-remaining">
              <p className="balance-label"> Remaining Budget: </p>
              <input
                className="form-control input-boxes"
                value={"$ " + reeemaining}
                readOnly
              />
            </div>
          </div>
          <div className="listy9" id="add_more">
            {eventTypeOf == "close_event" ? null : (
              <button className="btn btn-primary clickme" onClick={handleShow}>
                Add New Expense
              </button>
            )}
          </div>
          <div className="accordion" id="accordionExample">
            <div className="budget_table">
              <table className="table tableC table-responsive">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Expense Summary</th>
                    <th scope="col">Payment Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Remaining Budget</th>
                    {/* <th scope="col">Added at</th> */}
                    {/* <th scope="col">Added by</th> */}
                    <th scope="col">Delete</th>
                    {/* <th scope="col">Column 8</th> */}
                  </tr>
                </thead>
                <tbody>
                  {budgetList &&
                    budgetList?.map((item, index) => {
                      expenseBudget = expenseBudget - item.expense_amount;
                      return (
                        <>
                          <tr id={`budget_id_${item._id}`}>
                            <th scope="row" key={index}></th>
                            <td>{item.expense_summary}</td>
                            {/* <td>{item.payment_date}</td> */}
                            {/* <td>{item.payment_date}</td> */}

                            <td>
                              {" "}
                              {item.payment_date
                                ? moment(item.payment_date).format("DD/MM/YYYY")
                                : ""}
                            </td>
                            <td>{item.expense_amount}</td>
                            <td> {expenseBudget} </td>
                            {/* <td>7/12/2022, 5:37AM</td>
                                          <td><span className='budgetSpan'>Roxy Landenrde</span></td> */}
                            <td>
                              <Link
                                to="#"
                                onClick={() => {
                                  getSingleBudget(item._id);
                                }}
                              >
                                <i class="fas fa-pencil-alt"></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={() => {
                                  delete_budget(item._id, item.expense_amount);
                                }}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Link>
                            </td>
                            <td></td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Modal Content Starts */}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        className="modal-dialog-scrollable"
      >
        <Modal.Header className="form-header" closeButton>
          <Modal.Title className="modal-heading contacts-model">
            Add Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contacts-form-body">
          <Row className="mb-3">
            <Form.Group controlId="formEventBudget">
              <Form.Label className="all-contacts-labels">
                Total Event Budget:
              </Form.Label>
              <Form.Control
                className="balance-board"
                type="text"
                placeholder="$9,050.85"
                readOnly
                value={"$ " + totalBudget}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="expensSummary">
              <Form.Label className="all-contacts-labels">
                Enter Expense Summary
              </Form.Label>
              <Form.Control
                className="input-text-placeholders"
                type="text"
                placeholder=""
                name="expense_summary"
                controlId="expense_summary"
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="expenseAmount">
              <Form.Label className="all-contacts-labels">
                Enter Expense Amount
              </Form.Label>
              <Form.Control
                className="input-text-placeholders"
                type="number"
                placeholder=""
                name="expense_amount"
                controlId="expense_amount"
                required
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formPhoneNumber">
              <Form.Label className="all-contacts-labels">
                Payment Date
              </Form.Label>
              <Form.Control
                className="input-text-placeholders"
                type="date"
                placeholder="DD/MM/YYYY"
                name="payment_date"
                controlId="payment_date"
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className="all-contacts-labels">Category</Form.Label>
              <Form.Select name="categories_id" controlId="category" required>
                <option value="">--Select Checklist--</option>{" "}
                {getUpdateData.map((item) => (
                  <option key={item._id} value={item.id}>
                    {" "}
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formNotes">
              <Form.Label className="all-contacts-labels">
                Related Checklist Item
              </Form.Label>
              <Form.Select
                name="related_checklist"
                controlId="checklist_item"
                required
              >
                <option value="">--Select Related Task--</option>{" "}
                {taskList.map((st, index) => (
                  <option key={index} value={st._id}>
                    {st.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formNotes">
              <Form.Label className="all-contacts-labels">
                Remaining Budget
              </Form.Label>
              <Form.Control
                className="input-text-placeholders disabled remaining-budget-score"
                required
                type="text"
                placeholder="$9,050.85"
                value={"$ " + (totalBudget - expenseAmount)}
                readOnly
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="add-expense-btn"
            type="button"
            onClick={() => {
              addExpense();
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i> Add Expense
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Content Ends */}

      {/* Edit Modal Content Starts */}
      <Modal
        show={showEditModal}
        onHide={handleEditModalClose}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        className="modal-dialog-scrollable"
      >
        <Modal.Header className="form-header" closeButton>
          <Modal.Title className="modal-heading contacts-model">
            Edit Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contacts-form-body">
          <Row className="mb-3">
            <Form.Group controlId="editformEventBudget">
              <Form.Label className="all-contacts-labels">
                Total Event Budget:
              </Form.Label>
              <Form.Control
                className="balance-board"
                type="text"
                placeholder="$9,050.85"
                readOnly
                value={"$ " + totalBudget}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="editexpensSummary">
              <Form.Label className="all-contacts-labels">
                Enter Expense Summary
              </Form.Label>
              <Form.Control
                className="input-text-placeholders"
                type="text"
                placeholder=""
                name="expense_summary"
                controlId="expense_summary"
                defaultValue={getBudgetDetail.expense_summary}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="editexpenseAmount">
              <Form.Label className="all-contacts-labels">
                Enter Expense Amount
              </Form.Label>
              <Form.Control
                className="input-text-placeholders"
                type="number"
                placeholder=""
                name="expense_amount"
                controlId="expense_amount"
                defaultValue={getBudgetDetail.expense_amount}
                required
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="editformPhoneNumber">
              <Form.Label className="all-contacts-labels">
                Payment Date
              </Form.Label>
              <Form.Control
                className="input-text-placeholders"
                type="date"
                placeholder="DD/MM/YYYY"
                name="payment_date"
                controlId="editpayment_date"
                defaultValue={getBudgetDetail.payment_date}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="editformGridState">
              <Form.Label className="all-contacts-labels">Category</Form.Label>
              <Form.Select
                name="categories_id"
                controlId="editcategory"
                required
              >
                <option>--Select Checklist--</option>{" "}
                {getUpdateData.map((item) => (
                  <option
                    key={item._id}
                    value={item.id}
                    selected={
                      getBudgetDetail.category_id == item.id ? "selected" : ""
                    }
                  >
                    {" "}
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="editformNotes">
              <Form.Label className="all-contacts-labels">
                Related Checklist Item
              </Form.Label>
              <Form.Select
                name="related_checklist"
                controlId="editchecklist_item"
                required
                defaultValue={getBudgetDetail.related_checklist}
              >
                <option defaultValue="">--Select Related Task--</option>{" "}
                {taskList.map((st, index) => (
                  <option
                    key={index}
                    value={st._id}
                    selected={
                      getBudgetDetail.related_checklist == st.id
                        ? "selected"
                        : ""
                    }
                  >
                    {st.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formNotes">
              <Form.Label className="all-contacts-labels">
                Remaining Budget
              </Form.Label>
              <Form.Control
                className="input-text-placeholders disabled remaining-budget-score"
                required
                type="text"
                placeholder="$9,050.85"
                value={"$ " + (totalBudget - expenseAmount)}
                readOnly
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="add-expense-btn"
            type="button"
            onClick={() => {
              editExpense(getBudgetDetail._id);
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i> Edit Expense
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit Modal Content Ends */}
    </>
  );
};
export default Budgets;
