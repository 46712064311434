import React, { useEffect, useState } from 'react';
import { Link,useLocation } from 'react-router-dom'; 
const AdminSideBar = () => {
    const [url, setUrl] = useState(null);
    const location = useLocation();
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);
    return (
        <>        
         <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
          <div class="position-sticky ">
            <ul class="nav flex-column mt-3 side_list_style">
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Dashboard1' ? 'active' : '')}`} to="/Dashboard1"> 
                        <span data-feather="home"></span> Dashboard 
                    </Link>
                </li>
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Events' ? 'active' : '')}`} to="/Events"> 
                        <span data-feather="file"></span> Events 
                    </Link>
                </li>
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Users' ? 'active' : '')}`} to="/Users"> 
                        <span data-feather="users"></span> Users 
                    </Link>
                </li>
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Blogs' ? 'active' : '')}`} to="/Blogs"> 
                        <span data-feather="users"></span> Blog 
                    </Link>
                </li> 
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Subscription' ? 'active' : '')}`} to="/Subscription">                    
                        <span data-feather="users"></span>Subscription Plan 
                    </Link>
                </li>
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Category' ? 'active' : '')}`} to="/Category">                    
                        <span data-feather="users"></span>Category
                    </Link>
                </li>
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Sub-category' ? 'active' : '')}`} to="/Sub-category">                    
                        <span data-feather="users"></span>Sub Category
                    </Link>
                </li>
                <li class="nav-item">
                    <Link class={`nav-link ${(url === '/Checklist-Data' ? 'active' : '')}`} to="/Checklist-Data">                    
                        <span data-feather="users"></span>Task 
                    </Link>
                </li>
               <li class="nav-item"></li>
            </ul> 
          </div>
        </nav>
        </>
    );
}

export default AdminSideBar;
