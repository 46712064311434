import React, {useState, useEffect} from 'react'; 
import '../../SuperAdmin/css/style.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import  get_url  from '../../events/common/ApiCaller'
import axios from "axios";
import { toast } from "react-toastify";

const AddTaskModal = (props) => 
{  
    const [sub_categroy, setSubCategroy] = useState([])
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    
    const onSubmit = async (data) => {            
        const headers = {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'multipart/form-data',
        }     
        await axios.post(`${get_url.local_url}add_define_task`, data, headers )
        .then(response => {
           const { data } = response
           props.setShow(false)
           toast.success(data.message);
           props.getAllTask()
        }).catch(error => {
           throw (error);
        });
    }  
    
    const getAllCategory = async() => {      
        const { data } = await axios.get(`${get_url.local_url}sub_category`)
        setSubCategroy(data.data)
    }

    useEffect(() => {
      getAllCategory();
    }, [ ]);
    return( 
   <>
    <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Sub Category</Form.Label>
                    <Form.Select aria-label="Default select example" {...register('checklist', { required: true })}>
                        <option selected>Select Sub Category</option>
                        {
                            sub_categroy.length > 0 && 
                            sub_categroy.map((data) =>
                            <option value={data._id}>{data.name}</option>)
                        }                      
                    </Form.Select>
                </Form.Group> 
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Task Name</Form.Label>
                    <Form.Control type="text" {...register('name', { required: true })} placeholder="Enter Task Name" />                   
                </Form.Group>        
                <Button variant="primary" type="submit">
                    Add
                </Button>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>         
        </Modal.Footer>
      </Modal>
    </> 
    ); 
} 
export default AddTaskModal;