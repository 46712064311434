import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import $ from "jquery";
import moment from "moment";

const EventSummary = () => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [eventTypeName, setEventTypeName] = useState("");

  const submitForm = () => {
    setShowModal(true);
    // setTimeout(function myStopFunction() {
    //   navigate("/GetStarted");
    // }, 3000);
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    await axios
      .get(`${get_url.local_url}event-data/${event_id}`)
      .then((response) => {
        const get_event_data = response.data[0];
        const get_event_type_data = response.data[0].event_type_data;
        get_event_data.event_platform = capitalizeFirstLetter(
          get_event_data.event_platform.replace("_", " ")
        );
        setEventData(get_event_data);
        setEventTypeName(get_event_type_data.name);
      });
  };

  function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  }

  useEffect(() => {
    fetchEventData();
  }, []);

  return (
    <>
      <Header />
      <div>
        <section className="planing_outer">
          <div className="container">
          <Link to="/step7">
                {" "}
                <i
                  style={{ fontSize: "50px" }}
                  class="fa-sharp fa-solid fa-arrow-left"
                ></i>
              </Link>
            <div className="inner_heading">
              <h4>Your event info summary</h4>
            </div>
            <div className="container">
              <div className="row top_specing8">
                <div className="col-lg-4 col-md-4">
                  <div className="create_outer1">
                    <p>Event Type</p>
                    <h4>{eventTypeName}</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="create_outer1">
                    <p>Event Mode</p>
                    <h4>{eventData.event_platform}</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="create_outer1">
                    <p>Event Name</p>
                    <h4>{eventData.name}</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="create_outer1">
                    <p>Date </p>
                    <h4>
                      {moment(eventData.start_date).format("MM-DD-YYYY")} -{" "}
                      {eventData.end_date == "Invalid date"
                        ? "No date Selected"
                        : moment(eventData.end_date).format("MM-DD-YYYY")}
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="create_outer1">
                    <p>City </p>
                    <h4>{eventData.city}</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="create_outer1">
                    <p>Size </p>
                    <h4>{eventData.event_size} Attendees</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 summery_center">
                  <div className="row">
                    <div className="col-lg-6 summery_center">
                      <div className="create_outer1">
                        <p>Total Budget </p>
                        <h4>${eventData.budget}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="crete_oter8">
                      <p className="p_text">You can always edit it later</p>
                      <button
                        onClick={(e) => {
                          submitForm(e);
                        }}
                        className="btn btn-primary createEventBtn"
                        type="button"
                      >
                        Create My Event
                      </button>
                      {showModal && (
                        <div>
                          <section className="creating_event pop-modalCustom">
                            <div className="container">
                            
                              <div className="crey8">
                     
                                <a
                                  href="GetStarted"
                                  className="btn Getstarted-1"
                                  type="button"
                                >
                                  Easy Events is Creating Your Event
                                </a>
                              </div>
                            </div>
                          </section>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EventSummary;
