import React from 'react';
import {Link} from "react-router-dom";

const Footer = () =>{
    const handleInstagram = () => {
        window.open('https://www.instagram.com/easyeevents', '_blank');
    }
    const handleFacebook = () => {
        window.open('https://www.facebook.com/easyeevents', '_blank');
    }
    const handleTwitter =() =>{
        window.open('https://twitter.com/Easyeevents', '_blank');
    }
    return(
            <>
               <footer className="footer-back">
                <div className="footer-part">
                    <div className="footer-content">
                        <div className="footer-head">
                            <Link className="footer-category-heading-main" to="/Welcome">Easy Events</Link>
                            <p className="footer-li-main">Event planning for <u>everyone</u></p>
                        </div>
                        <div className="footer-links">
                            <ul className="footer-ul">
                                <p className="footer-category-heading">Explore</p>
                                {/* <li className="footer-li"> <Link to="">Features</Link></li> */}
                                <li className="footer-li"> <Link to="/AboutUs">About</Link></li>
                                <li className="footer-li"> <Link to="/PricingPlans">Pricing</Link></li>
                                <li className="footer-li"> <Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                            </ul>
                            <ul className="footer-ul">
                                <p className="footer-category-heading">Contact</p>
                                <li className="footer-li">Easy Events, 1234 Street Austin, TX 78701</li>
                            </ul>
                            <ul className="footer-ul">
                                <p className="footer-category-heading">Follow</p>
                                <li className="footer-li" onClick={handleInstagram}> <Link to="#"><i class=" social_media fa-brands fa-instagram"></i> Instgram</Link></li>
                                <li className="footer-li" onClick={handleFacebook}> <Link to="#"><i class="social_media fa-brands fa-facebook-f"></i>Facebook</Link></li>
                                <li className="footer-li" onClick={handleTwitter}> <Link To="#"><i class="social_media fa-brands fa-twitter"></i> Twitter</Link></li>
                            </ul>

                        </div>
                    </div>
                    <div className="copyright-tagline">
                        <p className="footer-li">2020 Easy Events. All rights reserved.</p>
                    </div>
                </div>
            </footer>

            </>
    )
}

export default Footer