import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Link, useNavigate} from 'react-router-dom';
import  Header  from './common/Header'; 
import  get_url  from './common/ApiCaller';
import axios from "axios";
import $ from 'jquery'; 

const Step7 = () => {
    const navigate = useNavigate()
    const [title, setTitle] = useState('') 
    
    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "event_id" : localStorage.getItem('event_id'),
        "user_role" : localStorage.getItem('role')
    }}

    const submitForm = ()=>{
        let get_budget = $('#budget').val()  
        if(get_budget == undefined || get_budget == ''){
            alert('Please enter budget amount.')
        }else{
            let event_data = {
                budget : get_budget,
                event_id : localStorage.getItem("event_id")
            }
            axios.post(`${get_url.local_url}event-all-form`,event_data, header).then((response) => {
                navigate('/EventSummary');
            })
        }
    } 
    const fetchEventData = async() => {
        let event_id = localStorage.getItem("event_id")
        if (null == event_id) {
            navigate('/PricingPlans')
        }
        await axios.get(`${get_url.local_url}event-data/${event_id}`).then((response) => {
            const get_event_data  = response.data[0] 
            if ('undefined' === typeof get_event_data) {
                navigate('/Dashboard')
            } 
            $('#budget').val(get_event_data.budget)
        })
    };
    useEffect(() => {
        fetchEventData();
    },[]) 

    const onlyNumber = (event) => {
        // console.log(e)
        event.target.value=event.target.value.replace(/[^0-9]/g,'')
    }

    return (
        <>
            <Header/>
            <div className="footstep-outer">
                <div className="foot-step shadow-lg"> Step 7 of 7</div>
            </div>
            <section className="planing_outer"> 
                <div className="container">
                <Link to="/step6">
                {" "}
                <i
                  style={{ fontSize: "50px" }}
                  class="fa-sharp fa-solid fa-arrow-left"
                ></i>
              </Link>
                    <div className="inner_heading">
                        <h4>Total Budget</h4>
                    </div>
                    <div className="row center_align">
                        <div className="col-lg-10 ">
                            <div className="input_outer"> 
                                <input type="text" placeholder="$" name="budget" id="budget" autoComplete="off" onKeyUp={(e) => onlyNumber(e)} maxLength={10} /><br></br> 
                                <p className="p_text"></p>
                            </div>
                        </div> 
                        <div className="btn_outer no_edit later3"> 
                            <button type='submit' className='btn btn-primary' onClick={(e)=>{submitForm()}}> Continue </button>
                        </div>
                    </div>
                </div>  
            </section> 
        </>
    )
}
export default Step7;