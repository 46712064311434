import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import get_url  from '../events/common/ApiCaller';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ForgotPassword = () => {

  let header = { headers: {
    "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
    "event_id" : localStorage.getItem('event_id'),
    "user_role" : localStorage.getItem('role')
  }}

  const validationSchema = yup.object().shape({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  })
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data) => {
    const forget_password_data = { 
      email: data.email
    }
    await axios.post(`${get_url.local_url}forget-password`,forget_password_data,header).then((response) => {
      console.log(response)
        if(response.data.status == '1'){
            alert("Check your email for change password.");
            toast.success("Email Link to your mail successfully !");
        }else {
            toast.error(response.data.message);
        }
    })  
    reset();
  }
    return (
        <>
      <div>
        <div className='signup_container shadow'>
            <div className='image_con'>
                 <div className='motive_text overlay1'>
                      <h2>Manage your events perfectly</h2>
                 </div>
            </div>
 
            <div className='form_con'>
                  <div className='action_form'>
                      <h2>Forgot Password</h2>
                      <p className='highlight_form_text'>Please reset your password </p>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Control className='E_mail' type="email" placeholder="Enter Your Email" name="email" {...register("email")} />
                            <p className='custom-error'>{errors.email?.message}</p>
                          </Form.Group>
                        </Row>
                        
                        <Button className="btn button_mg" type="submit" >
                            Submit
                        </Button>
                      </Form>
                  </div>
            </div>
          
        </div>
      </div>
    </>
    );
}

export default ForgotPassword;
