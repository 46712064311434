import React from 'react';
import MainHeader from '../components/shared/MainHeader';
import Footer from "../components/shared/Footer";


const AboutUs = () => {
    return (
        <>
            <MainHeader/>
            <section> 
                <div className='about_banner_Outer'>
                    {/* <img className='About_banner' src='https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='About Banner'/> */}
                    <h2 className='About_b_heading'> About Easy Events</h2>
                </div>
            {/* <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://images.pexels.com/photos/2526105/pexels-photo-2526105.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://images.pexels.com/photos/57980/pexels-photo-57980.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p>
                    </Carousel.Caption>
                </Carousel.Item>
                </Carousel> */}
            </section>
            <section>
                <div className='about_container'>
                     
                    {/* <h2 className='About_b_heading'> About <br /> Easy Events</h2> */}
                    <p className='About_text'> The journey of Easy Events began, like many other products, with frustration. The feeling was due to the lack of affordable and accessible event planning softwares available. Running into ‘Request Demo’ paywalls and free tools that lack useful functionality was the norm. The better tools seemed setup only for corporate use-cases, but none of them were created with the everyday event planner in mind.</p>
                    <p className='About_text'> Meanwhile, Ian Atwater was starting his own event planning career as an event planner and technical producer. Using spreadsheets that weren’t backed up anywhere, manually performing repeatable processes for 5 years was simply the last straw.</p>
                    <p className='About_text'> It was 2018 when Ian began working on Easy Events. To create an event planning solution that was accessible and easy to use. To streamline event planning processes to empower the planners on the ground who already do so much. Easy Events generates checklists for you, pre-populates agendas, budget items and much more.</p>
                    <p className='About_text'> As with many endeavors, the progress is limited by time and money but that hasn’t stopped the work that continues to go into Easy Events.</p>
                    <p className='About_text'> We’re delighted to work on something that helps you, another event planner, get more done with less. We hope you’ll let us know how we can further improve Easy Events, to create the first and only, event planning platform for everyone.</p>

                </div>

                
                
            </section>
              <Footer/>
        </>
    );
}

export default AboutUs;
