import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import get_url  from '../events/common/ApiCaller';
import {Modal, Button, Col, Row, Form} from 'react-bootstrap';
import SideBar from '../events/SideBar';
import EventDetails from '../events/EventDetails'
import { ThreeDots } from 'react-loader-spinner'
import $, { error } from 'jquery';

const Profile = () => {
    const [userDetail, setUserDetail] = useState([]);
    const[isLoaderDisplay,setIsLoaderDisplay] = useState(false);

    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "event_id" : localStorage.getItem('event_id'),
        "user_role" : localStorage.getItem('role')
    }}

    const updateUser = async () => {
        setIsLoaderDisplay(true)
  
        let get_first_name = $(`#first_name`).val()
        let get_last_name = $(`#last_name`).val()
        let get_email = $(`#email`).val()
        let get_phone_number = $(`#phone_number`).val()
  
        let user_data = {
           first_name : get_first_name,
           last_name : get_last_name,
           email : get_email,
           phone_number : get_phone_number
        } 
        await axios.post(`${get_url.local_url}update-user-data`,user_data,header).then((response) => { 
            alert('Profile update successfully.')
            getUserDetail();
            setIsLoaderDisplay(false)
        }).catch((error) => {
           setIsLoaderDisplay(false)
        })
     }
    const getUserDetail = async() => { 
        await axios.get(`${get_url.local_url}get-user`,header).then((response) => {
            const get_user_detail  = response.data.data[0]
            setUserDetail(get_user_detail) 
        })
    }

    useEffect(() => { 
        getUserDetail()  
    },[]);

    return (
        <> 
        {isLoaderDisplay? <div className='overlay'><div className="center_dots"><ThreeDots height="80" width="80" radius="9" color="#0d6efd" /></div></div>:''}

        <section className="get_start">
            <div className="container">
                <EventDetails/>
            </div>
        </section>

        <section className="check_list">
        <SideBar />
            <div className='pofile_Cont mt-3'>
                <div className='profl_div shadow'>
                   
                    <div className='Profile_Details'>
                        {/* <h3 className='Name'>  {userDetail.first_name} {userDetail.last_name} </h3> */}
                        <div>
                            <Row className="mb-3">
                                {/* <div as={Col} className='profile_img'>
                                    <img src='../App/public/images/Icon_Profile.png' alt='Profile image' />
                                </div> */}
                                
                                {/* <Form.Group  controlId="formGridEmail">
                                    <Form.Control type='file' placeholder="First Name"/>
                                </Form.Group> */}

                                {/* <div className="mt-3" as={Col}>
                                    <form method="post" enctype="multipart/form-data">
                                        <label className="btn_get" for="apply"><input className="get_file" type="file" name="" id="apply" accept="image/*,.pdf" /> <i className="fas fa-camera"></i> Change Photo</label>
                                    </form>
                                </div> */}

                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="first_name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control placeholder="First Name" defaultValue={userDetail.first_name} id="first_name"/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="last_name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control  placeholder="Last Name" defaultValue={userDetail.last_name}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" defaultValue={userDetail.email}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="phone_number">
                                    <Form.Label>Contact Number</Form.Label>
                                    <Form.Control type="number" placeholder="Contact Number" defaultValue={userDetail.phone_number}/>
                                </Form.Group>
                            </Row>

                            {/* <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control placeholder="1234 Main St" />
                            </Form.Group> */}

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                               
                            </Form.Group>


                            {/* <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>State</Form.Label>
                                <Form.Select defaultValue="Choose...">
                                    <option>Choose...</option>
                                    <option>...</option>
                                </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control />
                                </Form.Group>
                            </Row> */}

                            <Button variant="primary" type="button" onClick={()=>{updateUser()}}>
                                Update Data
                            </Button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Profile;
