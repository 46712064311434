import React,{useEffect, useState} from 'react'; 
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Layout/AdminSidebar';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import get_url  from '../../events/common/ApiCaller';
import $, { error } from 'jquery';

const AdminUpdateProfile = () => {

  const [userData, setUserData] = useState({}); 
  
  const navigate = useNavigate();
  const user_id = useLocation().search;  
  const userID = new URLSearchParams(user_id).get('user');  

  let header = { headers: {
    "Authorization" : `Bearer ${localStorage.getItem('tokens')}`,
    // "event_id" : localStorage.getItem('event_id'),
    "user_role" : localStorage.getItem('role')
  }}
  
  const getUserDetail = async() => { 
    await axios.get(`${get_url.local_url}get-user`,header).then((response) => {
        const get_user_detail  = response.data.data[0]
        setUserData(get_user_detail);
        console.log(userData, get_user_detail)
    })
  }

  const updateUser = async () => {

    let get_first_name = $(`#first_name`).val()
    let get_last_name = $(`#last_name`).val()
    let get_email = $(`#email`).val()
    let get_phone_number = $(`#phone_number`).val()

    let user_data = {
       first_name : get_first_name,
       last_name : get_last_name,
       email : userData.email,
       phone_number : userData.phone_number
    } 
    await axios.post(`${get_url.local_url}update-user-data`,user_data,header).then((response) => { 
        alert('Profile update successfully.')
        getUserDetail();
    }).catch((error) => {
    })
 }

    useEffect(() => {
        getUserDetail();
      }, [ ]);
    return (
        <>
        <AdminHeader />
          <div class="container-fluid">
            <div class="row">
            <AdminSideBar />
              <main class="col-md-9 ms-sm-auto col-lg-10 "> 
              <div className='events_list Page_box'>
                  <div> 
                    <h3 className='Welcome_text'>Update Profile</h3>
                  </div>
                  <div className='form_con'>
                  <div className='action_form'>
                      <Form >
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="first_name">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control placeholder="First Name" defaultValue={userData.first_name} id="first_name"/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="last_name">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control  placeholder="Last Name" defaultValue={userData.last_name}/>
                            </Form.Group>
                        </Row>

                          <Button className="btn button_mg" type="submit" onClick={()=>{updateUser()}}>
                            Update Profile
                          </Button>
                      </Form>
                  </div>
            </div>
                </div>
              </main>
            </div>
          </div>
    </>
    );
}

export default AdminUpdateProfile;
