import { useEffect } from 'react';
import axios from "axios";
import  get_url  from '../events/common/ApiCaller';
import {useNavigate} from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    let hasErrors = true;

    useEffect(() => { 
        if (hasErrors === true) {
            userDetail()
            successSubscription()
        }
    },[hasErrors]);

    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "user_role" : localStorage.getItem('role')
    }}

    const userDetail = () => {
        hasErrors = false;
        axios.get(`${get_url.local_url}get-user`, header).then((response) => {
            const userData  = response.data.data[0] 
            if ('undefined' === userData) {
                navigate('/Login')
            }
        })
    }

    const successSubscription = () => {
        hasErrors = false;
        axios.get(`${get_url.local_url}success-subscription`, header).then((response) => {
            if (response.data.status != 200) {
                alert(response.data.message);
                navigate('/Dashboard');
            }
            alert(response.data.message);
            navigate('/Step1');
        })
    }
    
}
export default PaymentSuccess;