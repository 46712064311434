import React, { useEffect, useState, usePopup } from "react";
// import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from "react-toastify";
import  get_url  from '../events/common/ApiCaller';
import { Link } from 'react-router-dom';  
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from "axios";
import style from '../App/public/css/style.css';
// import Header from "../../components/shared/MainHeader";
// import MainHeader from "../../components/shared/MainHeader";
// import Footer from "../../components/shared/Footer"
import AppleLogin from 'react-apple-login'

const SignUp = (props) => {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
    // email: yup.string().email().required("email name is required"),
    first_name:yup.string().required("First Name is required"),
    last_name:yup.string().required("Last Name is required"),
   // phone_number:yup.string().matches(new RegExp('[0-9]{10}')).required("Phone Number is required"),
    agree: yup.bool() // use bool instead of boolean
    .oneOf([true], "You must accept the terms and conditions"),
    password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    confirm_password: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match")
  });
 
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  /** Validations Ends */

  /**Check Box */
  const [agree, setAgree] = useState(false);
  const [phone, setPhone]= useState(false);
  const checkboxHandler = () => { 
    setAgree(!agree);
  }

  const onSubmit = (data) => {
    
      const headers = { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" }
      const data1 = { first_name: data.first_name, last_name:data.last_name, email:data.email, password:data.password, role:"admin" , agree:data.agree};
      axios.post(`${get_url.local_url}signup`,data1,{headers:headers}).then((response) => {
        console.log('response.data.status',response.data)
          if (response.data.status == '200') {
              alert("User Registered Successfully !");  
              localStorage.setItem('login_token',response.data.token)
              localStorage.setItem('role', response.data.data.role);
              navigate('/Welcome');
          }else{
              alert(response.data.message)
              // toast.error(response.data.message);
          }
      });
      reset();
  } 


  const appleResponse = response => {
    if (!response.error) {
      axios
        .post(get_url.local_url+"auth", response)
        .then((res) => {
          
          localStorage.setItem('login_token',res.data.token)
              localStorage.setItem('role', res.data.data.role);
              navigate('/Welcome');
          
          } 
          // this.setState({ authResponse: res.data })
        )
        .catch(err => console.log(err));
    }
  };



  return (
    <>
      {/* <MainHeader/> */}
      <div>
        <div className='signup_container shadow'>
            <div className='image_con'>
                 <div className='motive_text overlay1'>
                  <h2 className="Motive_text_tell">Manage your events perfectly</h2>
                 </div> 
            </div> 
            <div className='form_con'>
                  <div className='action_form'>
                      <h2>Sign Up</h2>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                              <Form.Control className='F_name' placeholder="First name"  name="first_name" {...register('first_name', { required: true })} autoComplete="off" />
                              {errors.first_name &&<span className='custom-error'>First Name is required</span>}    
                          </Form.Group>
                        
                          <Form.Group as={Col} controlId="formGridPassword">
                               <Form.Control  className='F_name' placeholder="Last name" name="last_name" {...register('last_name', { required: true })} autoComplete="off" />  
                               {errors.last_name &&<span className='custom-error'>Last Name is required</span>}  
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Control className='E_mail' type="text" placeholder="Enter email"  name="email" {...register("email")} autoComplete="off" />
                            <p className='custom-error'>{errors.email?.message}</p>
                          </Form.Group>
                        </Row>
                        
                        {/* <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridPassword" className="phone_number">
                          <PhoneInput className="w-100" placeholder="Enter phone number" inputProps={{ name: 'phone_number', required: true, autoFocus: true}} value={phone} onChange={(e) => setPhone(e)} />
                          </Form.Group>
                        </Row> */}

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Control className='P_assword' type="password" placeholder="Password" name="password" {...register('password', { required: true })} maxlength="20" autoComplete="off"/> 
                              {errors.password &&<span className='custom-error'>{errors.password?.message}</span>} 
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Control className='P_assword' type="password" placeholder="Confirm Password" name="confirm_password" {...register('confirm_password', { required: true })} autoComplete="off"/>
                              {errors.confirm_password &&<span className='custom-error'>{errors.confirm_password?.message}</span>} 
                          </Form.Group>
                        </Row>
                        
                        <Form.Group className="mb-3 Form_con_2" id="formGridCheckbox">
                          <Form.Check  type="checkbox"  onChange={checkboxHandler} label="Agree to our Terms and conditions"  name="agree" {...register('agree', { required: true })} autoComplete="off"/>
                          {errors.agree &&<span className='custom-error'>{errors.agree?.message}</span>} 
                        </Form.Group>

                        <Button className="btn button_mg" type="submit" >
                          Create Account
                        </Button>

                        <div className="login_with_div"> 
                           <div className='more_login_text'> Or Login with</div>
                        </div> 
                        <div className='Login_with_link'>
                          <AppleLogin 
                          clientId="app.easyevents.webapp"
                          scope="name email"
                          redirectURI="https://easyevents.app/SignUp" 
                          callback={appleResponse}
                          responseMode='query'
                          />
                        </div> 
                        <div className="not_account">Already have an account? <Link to="/Login">Login</Link></div> 
                      </Form>
                  </div>
            </div>
          
        </div>
      </div>
      {/* <Footer/>  */}
    </>
  )
}
export default SignUp;