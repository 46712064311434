import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import get_url from "./common/ApiCaller";
import EventDetails from "./EventDetails";
import axios from "axios";
import SideBar from "./SideBar";
import $ from "jquery";
import Multiselect from "multiselect-react-dropdown";
import { ThreeDots } from "react-loader-spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Moment from "moment";
import moment from "moment";

const Contacts = (props) => {
  window.scrollTo(0, 0);

  const [showOne, setShowOne] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);

  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);
  const handleCloseEditContact = () => setShowEditContact(false);

  const [selectedValue, setSelectedValue] = useState([]);
  const [startDate, setStartDate] = useState("");

  const [openCalander, setopenCalander] = React.useState(false);
  const [userListing, setUserListing] = React.useState(false);
  /** Modal Hooks Start */
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [append, setAppend] = useState("");
  const [checkname, setCheckname] = useState("");
  const [noOfRows, setNoOfRows] = useState(1);
  const [multiselectValue, setMultiselectValue] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [taskList, setTaskList] = useState([]);
  const exampleInput = useRef();
  const rowsData = [];
  const [categoryList, setCategoryList] = useState([]);
  const [eventD, setEventD] = useState(false);
  const [checkedData, setCheckedData] = useState(false);
  const [checkedArrData, setCheckedArrData] = useState([]);
  const [optionSelect, setOptionSelect] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [state, setState] = useState("");
  const [assignUser, setAssignUser] = useState("");
  const [checkedBox, setCheckedBox] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [modalOpen, setModalOpen] = useState(true);
  const [getUpdateData, setgetUpdateData] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const [contactListName, setContactListName] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const eventTypeOf = localStorage.getItem("event_type");

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const navigate = useNavigate();

  const userDetail = () => {
    axios.get(`${get_url.local_url}get-user`, header).then((response) => {
      const userData = response.data.data[0];
      if ("undefined" === userData) {
        navigate("/Login");
      }
      setUserDetails(userData);
    });
  };

  const delete_contact_list = async (contact_list_id) => {
    if (window.confirm("Are you sure to delete this contact list.")) {
      await axios
        .delete(
          `${get_url.local_url}delete-contact-list/${contact_list_id}`,
          header
        )
        .then((response) => {
          fetch_contact_list_data();
        })
        .catch((error) => console.log(error));
    } else {
      setIsLoaderDisplay(false);
    }
  };

  const delete_contact = async (contact_id) => {
    if (window.confirm("Are you sure to delete this contact.")) {
      await axios
        .delete(`${get_url.local_url}delete-contact/${contact_id}`, header)
        .then((response) => {
          fetch_contact_list_data();
        })
        .catch((error) => console.log(error));
    } else {
      setIsLoaderDisplay(false);
    }
  };
  const add_contact = async (contact_list_id) => {
    const selected_category_id = categoryName.map((u) => u.name).join(", ");

    let get_person_name = $(`#person_name_${contact_list_id}`).val().trim();
    let get_company_name = $(`#company_name_${contact_list_id}`).val().trim();
    let get_email = $(`#email_${contact_list_id}`).val().trim();
    let get_phone_number = $(`#phone_number_${contact_list_id}`).val().trim();
    let get_category = selected_category_id;
    let get_note = $(`#note_${contact_list_id}`).val().trim();
    let get_check_in_date = $(`#check_in_date_${contact_list_id}`).val().trim();

    if ("" === get_person_name) {
      alert("Please enter name of the person.");
      return false;
    }
    if ("" === get_company_name) {
      alert("Please enter name of the company.");
      return false;
    }
    if ("" === get_email) {
      alert("Please enter email of the person.");
      return false;
    }
    if ("" === get_phone_number) {
      alert("Please enter phone number of the person.");
      return false;
    }
    // if ('' === get_note) {
    //    alert ('Please enter note.'); return false;
    // }

    if ("" === get_check_in_date) {
      alert("Please enter check in date.");
      return false;
    }

    let contact_data = {
      person_name: get_person_name,
      company_name: get_company_name,
      email: get_email,
      phone_number: get_phone_number,
      category: get_category,
      note: get_note,
      check_in_date: get_check_in_date,
      contact_list_id: contact_list_id,
    };
    console.log("contact_data", contact_data);

    setIsLoaderDisplay(true);

    await axios
      .post(`${get_url.local_url}add-contact-data`, contact_data, header)
      .then((response) => {
        fetch_contact_list_data();
        $(".field_for_empty").val("");
        setCategoryName([]);
        $("[data-bs-dismiss=modal]").trigger({ type: "click" });
      })
      .catch((error) => console.log(error));

    if (
      $("#contact_list_id_" + contact_list_id)
        .find(".checklist_head")
        .hasClass("collapsed")
    ) {
      $("#contact_list_id_" + contact_list_id)
        .find(".checklist_head")
        .trigger("click");
    }
  };
  const add_contact_list = async () => {
    let conatct_list_name = $("#contact_list_name").val().trim();

    if (contactListName.includes(conatct_list_name.toLowerCase())) {
      alert("Conact list name already in use.");
      return false;
    }

    if (conatct_list_name == "") {
      alert("Please enter contact list name.");
      return false;
    }

    if (conatct_list_name != "") {
      if (50 < conatct_list_name.length) {
        alert("Contact list name should be less than 50 characters.");
        return false;
      }

      setIsLoaderDisplay(true);
      let contact_list_data = {
        contact_list_name: conatct_list_name,
      };
      await axios
        .post(
          `${get_url.local_url}add-contact-list-data`,
          contact_list_data,
          header
        )
        .then((response) => {
          fetch_contact_list_data();
          $("#contact_list_name").val("");
          // $("[data-bs-dismiss=modal]").trigger({ type: "click" });
          handleCloseOne();
        })
        .catch((error) => console.log(error));
    }
  };

  const fetch_contact_list_data = async () => {
    setIsLoaderDisplay(true);
    await axios
      .get(`${get_url.local_url}contact-list-data`, header)
      .then((response) => {
        const all_contact_list_data = response.data.data;
        setContactList(all_contact_list_data);
        setContactListName([]);
        setIsLoaderDisplay(false);
      })
      .catch((error) => console.log(error));
  };
  const getCategoryList = async () => {
    await axios
      .get(`${get_url.local_url}list-categories`, header)
      .then((response) => {
        const all_category_list_data = response.data.data;
        setCategoryList(all_category_list_data);
      })
      .catch((error) => console.log(error));
  };
  const handleSelect = (event) => {
    setCategoryName(event);
  };

  const handleRemove = (event) => {
    setCategoryName(event);
  };

  const getContact = async (contactId) => {
    if ("undefined" === typeof contactId || "" === contactId) {
      return false;
    }
    await axios
      .get(`${get_url.local_url}get-contact/` + contactId, header)
      .then((response) => {
        if (200 !== response.data.status) {
          alert(response.data.message);
          return false;
        }
        setContactDetails(response.data.data);
        setShowEditContact(true);
      });
  };

  const updateContact = async (contactId) => {
    if ("undefined" === typeof contactId || "" === contactId) {
      return false;
    }

    const selected_category_id = categoryName.map((u) => u.name).join(", ");

    let get_person_name = $(`#edit_person_name`).val().trim();
    let get_company_name = $(`#edit_company_name`).val().trim();
    let get_email = $(`#edit_email`).val().trim();
    let get_phone_number = $(`#edit_phone_number`).val().trim();
    let get_category = selected_category_id;
    let get_note = $(`#edit_note`).val().trim();
    let get_check_in_date = $(`#edit_check_in_date`).val().trim();

    if ("" === get_person_name) {
      alert("Please enter name of the person.");
      return false;
    }
    if ("" === get_company_name) {
      alert("Please enter name of the company.");
      return false;
    }
    if ("" === get_email) {
      alert("Please enter email of the person.");
      return false;
    }
    if ("" === get_phone_number) {
      alert("Please enter phone number of the person.");
      return false;
    }
    // if ('' === get_note) {
    //    alert ('Please enter note.'); return false;
    // }

    if ("" === get_check_in_date) {
      alert("Please enter check in date.");
      return false;
    }

    let contact_data = {
      person_name: get_person_name,
      company_name: get_company_name,
      email: get_email,
      phone_number: get_phone_number,
      category: get_category,
      note: get_note,
      check_in_date: get_check_in_date,
    };
    // console.log('contact_data',contact_data);

    // setIsLoaderDisplay(true)

    await axios
      .post(
        `${get_url.local_url}update-contact/` + contactId,
        contact_data,
        header
      )
      .then((response) => {
        fetch_contact_list_data();
        setShowEditContact(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetch_contact_list_data();
    getCategoryList();
    userDetail();
  }, []);

  const data = categoryList.map((item, index) => {
    if (
      localStorage.getItem("selected_category").includes(item._id) &&
      !getUpdateData.some(function (o) {
        return o["id"] === item._id;
      })
    ) {
      getUpdateData.push({ id: item._id, name: item.name });
    }
  });

  const listName = contactList.map((item, index) => {
    contactListName.push(item.name.toLowerCase());
  });

  const onlyNumber = (event) => {
    // console.log(e)
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
  };

  const [todayDate, setTodayDate] = useState(false);

  $(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;
    setTodayDate(maxDate);
  });

  return (
    <div>
      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>
      <section className="check_list">
        <SideBar />
        <div className="check_right">
          <div className="row">
            <div className="col-lg-9 col-md-6">
              <div className="assign_outer">
                <h2>Contacts</h2>
                <p>
                  Use this page to add and find contact information for your
                  event stakeholders
                </p>
              </div>
            </div>
          </div>
          <hr />
          <>
            {isLoaderDisplay ? (
              <div className="overlay">
                <div className="center_dots">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#0d6efd"
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {eventTypeOf == "close_event" ? null : (
              <div className="listy9" id="add_more">
                <a
                  href="#"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                  onClick={handleShowOne}
                >
                  New Contact List
                </a>
              </div>
            )}

            {contactList?.length > 0 &&
              contactList.map((contact_list_data, contact_list_key) => {
                return (
                  <div
                    className="accordion category_accordian"
                    id={`contact_list_id_${contact_list_data._id}`}
                  >
                    <div className={`item delitem`}>
                      <div className="item-header">
                        <h2 className="mb-0">
                          <div className="btn btn-link checklist_head">
                            <div className="title_head_outer1">
                              <span className="title_heading">
                                {contact_list_data.name}
                              </span>
                            </div>
                            <div className="right_options">
                              <div className="right90 mt-0">
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#add_contact_${contact_list_data._id}`}
                                >
                                  Add new contact
                                </Link>
                              </div>
                              <div>
                                <button
                                  className="btn btn-link checklist_head collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#show_hide_func_${contact_list_data._id}`}
                                  aria-expanded="false"
                                  aria-controls={"ar"}
                                >
                                  <i className="fa fa-angle-down down-btn"></i>
                                </button>
                              </div>
                              <div
                                className="delete_list"
                                onClick={() => {
                                  delete_contact_list(contact_list_data._id);
                                }}
                              >
                                <img
                                  className="delete-btn"
                                  src="../Assets/img/close_icon.png"
                                />
                              </div>
                            </div>
                          </div>
                        </h2>
                      </div>

                      {/* MODAL START */}
                      <div
                        className="modal custom_modal fade model_div"
                        id={`add_contact_${contact_list_data._id}`}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="utm_modalTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="utm_modalTitle">
                                {" "}
                                Add contact
                              </h5>
                              <div
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">
                                  {" "}
                                  <i class="fa-solid fa-xmark"></i>
                                </span>
                              </div>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-6">
                                  Person's Name
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder=" Person's Name"
                                    name=""
                                    id={`person_name_${contact_list_data._id}`}
                                    required
                                  />
                                </div>
                                <div className="col-md-6">
                                  Company Name
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Company Name"
                                    name=""
                                    id={`company_name_${contact_list_data._id}`}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Email
                                  <input
                                    type="email"
                                    className="form-control field_for_empty"
                                    placeholder="Email"
                                    name=""
                                    id={`email_${contact_list_data._id}`}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Phone Number
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Phone Number"
                                    name=""
                                    id={`phone_number_${contact_list_data._id}`}
                                    required
                                    onKeyUp={(e) => onlyNumber(e)}
                                    maxLength={10}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Categories
                                  <Multiselect
                                    className="form-control multi-select"
                                    options={getUpdateData}
                                    displayValue="name"
                                    name="categories_id[]"
                                    onSelect={(event) => {
                                      handleSelect(event);
                                    }}
                                    onRemove={(event) => {
                                      handleRemove(event);
                                    }}
                                    selectedValues={categoryName}
                                  />
                                  {/* <input type="text" className="form-control field_for_empty" placeholder="First Name" name="" id={`category_${contact_list_data._id}`} required/>  */}
                                </div>
                                <div className="col-md-6 mt-3 next_date">
                                  Next Check In Date
                                  <input
                                    type="date"
                                    className="form-control field_for_empty"
                                    placeholder="First Name"
                                    name=""
                                    id={`check_in_date_${contact_list_data._id}`}
                                    required
                                    min={todayDate}
                                  />
                                </div>
                                <div className="col-md-12 mt-3">
                                  Notes
                                  <textarea
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Note"
                                    name=""
                                    id={`note_${contact_list_data._id}`}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div className="para_save_outer">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        add_contact(contact_list_data._id);
                                      }}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* MODAL END */}

                      {/* EDIT CONTACT MODAL START */}
                      <Modal
                        show={showEditContact}
                        onHide={handleCloseEditContact}
                        //   backdrop="static"
                        keyboard={false}

                        //   size="lg"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="utm_modalTitle">
                                {" "}
                                Edit contact
                              </h5>
                              <div
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">
                                  {" "}
                                  <i
                                    class="fa-solid fa-xmark"
                                    onClick={handleCloseEditContact}
                                  ></i>
                                </span>
                              </div>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-6">
                                  Person's Name
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder=" Person's Name"
                                    name=""
                                    id={`edit_person_name`}
                                    required
                                    defaultValue={contactDetails.name}
                                  />
                                </div>
                                <div className="col-md-6">
                                  Company Name
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Company Name"
                                    name=""
                                    id={`edit_company_name`}
                                    required
                                    defaultValue={contactDetails.company_name}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Email
                                  <input
                                    type="email"
                                    className="form-control field_for_empty"
                                    placeholder="Email"
                                    name=""
                                    id={`edit_email`}
                                    required
                                    defaultValue={contactDetails.email}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Phone Number
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Phone Number"
                                    name=""
                                    id={`edit_phone_number`}
                                    required
                                    onKeyUp={(e) => onlyNumber(e)}
                                    maxLength={10}
                                    defaultValue={contactDetails.phone_number}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Categories
                                  <Multiselect
                                    className="form-control multi-select"
                                    options={getUpdateData}
                                    displayValue="name"
                                    name="edit_categories_id[]"
                                    onSelect={(event) => {
                                      handleSelect(event);
                                    }}
                                    onRemove={(event) => {
                                      handleRemove(event);
                                    }}
                                    selectedValues={categoryName}
                                  />
                                  {/* <input type="text" className="form-control field_for_empty" placeholder="First Name" name="" id={`category_${contact_list_data._id}`} required/>  */}
                                </div>
                                <div className="col-md-6 mt-3 next_date">
                                  Next Check In Date{" "}
                                  {moment(contactDetails.next_check_in).format(
                                    "DD/MM/YYYY"
                                  )}
                                  <input
                                    type="date"
                                    className="form-control field_for_empty"
                                    placeholder="First Name"
                                    name=""
                                    id={`edit_check_in_date`}
                                    required
                                    min={todayDate}
                                  />
                                </div>
                                <div className="col-md-12 mt-3">
                                  Notes
                                  <textarea
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Note"
                                    name=""
                                    id={`edit_note`}
                                    defaultValue={contactDetails.notes}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div className="para_save_outer">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        updateContact(contactDetails._id);
                                      }}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      {/* EDIT CONTACT MODAL END */}

                      <div
                        id={`show_hide_func_${contact_list_data._id}`}
                        className={`collapse`}
                        aria-labelledby={"head"}
                        data-parent={"#accor"}
                      >
                        <div className="t-p">
                          <div className="table-responsive">
                            <input
                              type="hidden"
                              name="checklist"
                              ref={exampleInput}
                            />
                            <table className="table custom_table">
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Person's Name</th>
                                  <th>Phone Number</th>
                                  <th>Email</th>
                                  <th>Company Name</th>
                                  <th>Next Check-in</th>
                                  <th>Categories</th>
                                  <th>Notes</th>
                                </tr>
                              </thead>
                              <tbody id={`add_task_${contact_list_data._id}`}>
                                <tr className="deltask">
                                  <td className="custom_ty6"></td>
                                  <td className="custom_labe8">
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="task_name"
                                      value={userDetails.first_name}
                                      disabled
                                    />
                                  </td>
                                  <td className="custom_labe8">
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="task_name"
                                      value={userDetails.phone_number}
                                      disabled
                                    />
                                  </td>
                                  <td className="custom_labe8">
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="task_name"
                                      value={userDetails.email}
                                      disabled
                                    />
                                  </td>
                                  <td className="custom_labe8"></td>
                                  <td className="custom_labe8"></td>
                                  <td className="custom_labe8"></td>
                                  <td className="custom_labe8"></td>
                                </tr>

                                {contact_list_data.contact_data.length > 0 &&
                                  contact_list_data.contact_data.map(
                                    (contact_data, task_key) => {
                                      return (
                                        <tr
                                          className="deltask"
                                          id={`contact_id_${contact_data._id}`}
                                        >
                                          <td className="custom_ty6">
                                            <img
                                              className="custom_ty66"
                                              src="../Assets/img/delete.png"
                                              onClick={() => {
                                                delete_contact(
                                                  contact_data._id
                                                );
                                              }}
                                            />
                                            <i
                                              class="fas fa-pencil-alt custom_ty66"
                                              onClick={() => {
                                                getContact(contact_data._id);
                                              }}
                                            ></i>
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.name}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.phone_number}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.email}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.company_name}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={
                                                contact_data.next_check_in
                                                  ? moment(
                                                      contact_data.next_check_in
                                                    ).format("DD/MM/YYYY")
                                                  : ""
                                              }
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.categories_id}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.notes}
                                              disabled
                                            />
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        </div>
        {/* <div className="right90">
         <button onClick={(e)=>{add_checklist()}}>Add data</button>
      </div> */}
      </section>

      {/* Modal Content Starts */}
      {/* <div className="modal custom_modal fade model_div" id="utm_profile_modal" tabIndex="-1" role="dialog" aria-labelledby="utm_modalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                     <div className="modal-header">
                        <h5 className="modal-title" id="utm_modalTitle"> Add new contact list</h5>
                        <div className="close" data-bs-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </div>
                     </div>
                     <div className="modal-body">
                           <div className="row">
                                 <div className="col-md-12">
                                    Contact List Name
                                    <input type="text" className="form-control" placeholder="First Name" name="" id="contact_list_name" required/> 
                                 </div>
                           </div> 
                           <div className="row mt-3">
                              <div className="col-md-9"></div>
                              <div className="col-md-3">
                                 <div className="para_save_outer"> 
                                       <button type="submit" className="btn btn-primary text-right" onClick={()=>{add_contact_list()}}>Submit</button>
                                 </div>
                              </div>
                           </div> 
                     </div>
                </div>
            </div>
        </div> */}

      <Modal
        show={showOne}
        onHide={handleCloseOne}
        //   backdrop="static"
        keyboard={false}
        //   size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new contact list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              Contact List Name
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                name=""
                id="contact_list_name"
                required
                maxLength={50}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className="btn btn-primary text-right"
            onClick={() => {
              add_contact_list();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Contacts;
