import React from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from "react-toastify";
import  get_url  from '../events/common/ApiCaller'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";



const Login = () => {
  const navigate = useNavigate();
  
  const validationSchema = yup.object().shape({
    email: yup.string().email().required("email name is required"),
    password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
  
  });
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
     
     const headers = { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" }

      const data1 = {  email:data.email, password:data.password, role:"superAdmin"};
   
      axios.post(`${get_url.local_url}superAdminLogin`, data1, headers )
      .then((response) => {
        if (response.data.status == '200'){    
          localStorage.setItem('tokens', response.data.token);
          localStorage.setItem('role', response.data.role);          
       
          
          // localStorage.setItem('user_id', JSON.stringify(response.data.data._id));
            if(response.data.role=="superAdmin"){ 
              // alert("Super Admin Login Successfully !");
              navigate('/Dashboard1')
             
            } 
            else{
              alert(response.data.message);
              navigate('/Login');
            }
         }
         
      
         else {
            alert(response.data.message);
           
         }
      });
  }
  return (
    <>
    {/* <MainHeader/> */}
    <div>
      <div className='signup_container shadow'>
        <div className='image_con'>
          <div className='motive_text overlay1'>
            <h2>Manage your events perfectly</h2>
          </div>
        </div>
        <div className='form_con'>
          <div className='action_form'>
            <h2>Login</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Control className='E_mail' type="email" placeholder="Enter email" name="email" {...register('email', { required: true })} autoComplete="off" /> {errors.email && <span className='custom-error'>Email is required</span>}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Control className='P_assword' type="password" placeholder="Password" name="password" {...register('password', { required: true })} autoComplete="off" /> {errors.password && <span className='custom-error'>{errors.password?.message}</span>}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Link className='Forgot_P' to="/ForgotPassword"> Forgot Password?</Link>
              </Row>
              <Button className="btn button_mg" type="submit"> Login </Button>
              {/* <div className="login_with_div">
                <div className='more_login_text'> Or Login with</div>
              </div>
              <div className='Login_with_link'>
                <Link className='link_App'>
                <i class="fa-brands fa-apple"></i>
                </Link>
              </div> */}
                {/* <div className="not_account">Don’t have an account?
                  <Link to="/Signup">Create new Account</Link>
                </div> */}
            </Form>
          </div>
        </div>
      </div>
    </div>
      {/* <Footer/>  */}
    </>
  )
}
export default Login;