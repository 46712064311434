import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Link, useNavigate} from 'react-router-dom';
import  Header  from './common/Header'; 
import  get_url  from './common/ApiCaller';
import axios from "axios";
import $ from 'jquery';  

const Step3 = (props) => {
    const navigate = useNavigate(); 
    const [eventData, setEventData] = useState({}); 
    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "event_id" : localStorage.getItem('event_id'),
        "user_role" : localStorage.getItem('role')
    }}

    const submitForm = ()=>{
        let event_name = $('#event_name').val() 
        if(event_name == undefined || event_name == ''){
            alert('Please enter event name.')
        }else{
            let event_data = {
                event_name : event_name,
                event_id : localStorage.getItem("event_id")
            }
            axios.post(`${get_url.local_url}event-all-form`, event_data, header).then((response) => { 
                navigate('/step4');
            })
        }
    }
    const fetchEventData = async() => { 
        let event_id = localStorage.getItem("event_id")
        if (null == event_id) {
            navigate('/PricingPlans')
        }
        await axios.get(`${get_url.local_url}event-data/${event_id}`).then((response) => {
            const get_event_data  = response.data[0] 
            if ('undefined' === typeof get_event_data) {
                navigate('/Dashboard')
            }
            setEventData(get_event_data)  
            $('#event_name').val(get_event_data.name)
        })
    };
    useEffect(() => {
        fetchEventData();
    },[]) 
    return (
        <>
        <Header/>
            <div className="footstep-outer">
                <div className="foot-step shadow-lg"> Step 3 of 7</div>
            </div> 
            <section className="planing_outer">  
                    <div className="container">
                    <Link to="/step2">
                {" "}
                <i
                  style={{ fontSize: "50px" }}
                  class="fa-sharp fa-solid fa-arrow-left"
                ></i>
              </Link>
                        <div className="inner_heading">
                            <h4>What's your event name?</h4>
                        </div>
                        <div className="row center_align">
                            <div className="col-lg-10 ">
                                <div className="input_outer">
                                    <input type="text" placeholder="Event Name" name="event_name" id="event_name" autoComplete="off"/><br></br> 
                                    <p className="p_text">You can always edit it later</p> 
                                </div>
                            </div>
                        </div>
                        <div className="btn_outer">
                            <button type='submit' className='btn btn-primary' onClick={(e)=>{submitForm()}}> Continue </button> 
                        </div>
                    </div> 
            </section>
        </>
    )
}
export default Step3;